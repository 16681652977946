import React from "react";
// Components
import Breadcrumbs from "../../Breadcrumbs";
import MetaHelmet from "../../SEO/MetaHelmet";
//Content
import { Headings, PagesData } from "../../jsComponents/ContentData";
import SeoContent from "../../SEO/SeoContent";

const list = PagesData.formsInstructions;
const TableRow = (props) => {
  const data = props.data;
  return (
    <tr>
      <td className="px-2">
        <a href={data.link} target="_blank" rel="noreferrer">
          {data.name}
        </a>
      </td>
      <td>: {data.description}</td>
    </tr>
  );
};
const ListSection = (props) => {
  const data = props.data;
  return (
    <div className="table-responsive table-sm">
      <div className={`mb2 ${data.id !== "l1" && "mt-4"}`}>
        <h2 className="h3 ps-2">{data.title}</h2>
        <hr />
      </div>
      <table>
        <tbody>
          {data.list.map((l) => {
            return <TableRow key={l.id} data={l} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

const Content = () => {
  return (
    <section id="contact" className="contact">
      <div className="container position-relative" data-aos="fade-up">
        <div className="row gy-4 d-flex justify-content-end">
          <div>
            {list.map((l) => {
              return <ListSection key={l.id} data={l} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
const FormsInstructions = () => {
  const [content] = SeoContent.pages.filter(
    (page) => page.page === "/forms-instructions"
  );
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.formsInstructions} />
      <Content />
    </>
  );
};
export default FormsInstructions;
