import React from "react";
import { NavLink } from "react-router-dom";

const taxCenterCard = (props) => {
  const card = props.card;
  return (
    <div
      className="mx-2 flip-card col-lg-3 col-md-6 service-item d-flex"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <h4
            className="title text-center  py-1"
            style={{ backgroundColor: "#3E54AC" }}
          >
            <NavLink
              style={{ color: "white" }}
              to={card.link}
              target={card.load}
              className="stretched-link"
              rel="noreferrer"
            >
              {card.title}
            </NavLink>
          </h4>
          <p align="justify" className="description m-0 ">
            &emsp; {card.paragraph}
            <br />
            <span
              className="align-middle text-primary"
              style={{ fontSize: "0.8rem", fontWeight: "500" }}
            >
              Click For More Details...
            </span>
          </p>
        </div>
        <div className=" flip-card-back text-center">
          <h4
            className="title text-center  py-1"
            style={{ backgroundColor: "#3E54AC" }}
          >
            <NavLink
              style={{ color: "white" }}
              to={props.card.link}
              target={card.load}
              className="stretched-link"
            >
              {props.card.title}
            </NavLink>
          </h4>
          <div className="py-5">
            <h4 className="h6 align-middle text-primary">
              <strong>Click For More Details..</strong>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default taxCenterCard;
