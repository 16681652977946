import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import { keyframes } from '@mui/system';

import ScrollingNews from './ScrollingNews';

import { News } from '../jsComponents/ContentData';

//Animation
const glowAnimation = keyframes`
  0% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.7);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 0, 0, 1), 0 0 30px rgba(255, 0, 0, 0.7);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.7);
  }
`;

const boiPoints = ["Every corporation or entity (LLC, S-Corporation, etc) that was created by filing a document with the Secretary of State or similar office is required to submit Beneficial Ownership Information.",
  "Reporting entities doing business before January 1st, 2024, will have until January 1st, 2025, to report their initial beneficial ownership report.",
  "Reporting entities that begin doing business between January 1st, 2024, and December 31st, 2024, will have 90 calendar days after public notice of their registration being in effect to file their initial report.",
  "Reporting entities that begin doing business after January 1st, 2025 will have 30 calendar days after their registration being in effect to file their initial report.",
  "Failure to report an initial BOI or correct false information on a previous report within 90 days of the deadline can result in a $500/ day fine and up to two years imprisonment with a $10,000 fine."];
const Hero = () => {
  return <>
    <section id="hero" className="hero d-flex align-items-center" style={{ paddingBottom: '3rem' }}>
      <div className="container">
        <div className="row">
          <div className="col-xl-4">
            {/* <!-- Main Heading  --> */}
            <h2 data-aos="fade-up">Certified Public Accountants</h2>
            <blockquote data-aos="fade-up" data-aos-delay="100">
              {/* <!-- Main Paragraph  --> */}
              <p align="justify">Elton M. Reneau & Co., PC CPAs are your trusted source for comprehensive accounting, tax & bookkeeping services, for individuals and businesses, in the New Braunfels area. Our team of highly skilled Certified Public Accountants strive to build long-term relationships with you, our clients and become trusted partners as you prepare for financial success.</p>
            </blockquote>
            <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
              {/* <!-- Main Button text --> */}
              <NavLink to="about" className="btn-get-started">Get Started</NavLink>
            </div>
            <div className="d-flex mt-2" data-aos="fade-up" data-aos-delay="200">
              {/* <NavLink to="services-boi" className="btn-important-news">Important News !</NavLink> */}
              <Grid sx={{ display: 'flex', flexDirection: 'column', width: '100svw', height: 400, marginTop: '1rem', borderBottomLeftRadius: 10 }}>
                <Typography variant='h1' sx={{
                  animation: `${glowAnimation} 3s ease-in-out infinite`,
                  bgcolor: 'red', borderTopLeftRadius: 10, borderTopRightRadius: 10, fontSize: '1.5rem', padding: '1rem', fontWeight: 'bold', textAlign: 'center', color: 'white'
                }}>Important News!</Typography>
                <Grid
                  sx={{ bgcolor: 'white', color: '#1B2F45', overflowY: 'auto' }}
                >
                  <Typography variant='h1' sx={{ fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'center', padding: '1rem' }}>Beneficial Ownership Information (BOI)</Typography>
                  <ul>
                    {boiPoints.map((item, index) => (
                      <li style={{ fontSize: '0.9rem', marginBottom: '0.5rem' }} key={index}>{item}</li>
                    ))}
                  </ul>
                </Grid>
                <Grid
                  sx={{ bgcolor: 'white', height: '5rem', padding: '0.5rem', textAlign: 'center', fontWeight: 'bold', overflowY: 'auto', color: '#1B2F45', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
                >
                  <Link className='btn btn-primary' to='services-boi'>Click for More Information <i className="bi bi-arrow-right-circle-fill"></i></Link>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </section >
    {News.length > 1 && <ScrollingNews />}
  </>
}

export default Hero;