import React from "react";

// Hooks
import { useLocation } from "react-router-dom";

// Components
import Breadcrumbs from "../Breadcrumbs";
import { Headings } from "../jsComponents/ContentData";

const TeamProfile = () => {
  const profileData = useLocation().state;

  if (profileData == null) {
    window.location.href = "/team";
  }

  Headings.teamProfile.pageNav = profileData.name;

  const Profile = () => {
    return (
      <section id="portfolio-details" className="portfolio-details">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-5">
              <img src={profileData.image} alt="" style={{ width: "90%" }} />
            </div>
            <div className="col-lg-7">
              <div className="portfolio-info">
                <h2>{profileData.name}</h2>
                <strong style={{ fontSize: "20px" }}>
                  {profileData.designation}
                </strong>
              </div>
              <div className="portfolio-description ">
                {profileData.description.map((paragraph) => {
                  return (
                    <p key={paragraph.id} align="justify">
                      &nbsp; {paragraph.paragraph}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  return (
    <>
      <Breadcrumbs content={Headings.teamProfile} />
      <Profile />
    </>
  );
};

export default TeamProfile;
