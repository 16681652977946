import React from "react";
// Components
import Breadcrumbs from "../../Breadcrumbs";
import MetaHelmet from "../../SEO/MetaHelmet";
// Content
import { Headings } from "../../jsComponents/ContentData";
import SeoContent from "../../SEO/SeoContent";

const topicsContent = [
  {
    title: "1040 Preparation",
    description:
      "The Form 1040 – Individual tax return is the most commonly filed tax form amongst all taxpayers. Even though it is the most common, there is no shortage of all the required information that must be filed on these returns. In addition, as being the most common form, the IRS has scrutinized the associated tax law, that much more. This inevitably can make the form 1040 that much more intricate. We are here to assist you in the filing of your 1040 tax returns and report the specific intricacies that your return might entail.",
  },
  {
    title: "Gift and Estate",
    description:
      " Gift and Estate taxes can be an annoyance to those wanting to disperse their wealth to their families and others. Elton M. Reneau & Company is ready to analyze the best financial vehicles to perform these transactions and as well as advise on how to create sustainable solutions for generations of wealth-sharing inside and outside your family.",
  },
  {
    title: "Year-End Tax Planning",
    description:
      "Our team is ready to provide you with monthly, quarterly, or semi-annual tax planning services so that you are not surprised by a large tax bill. We have the expertise to advise you on when and how to make estimated tax payments, plan for the tax effects of significant life events, and assist in future transactions to create the most tax-beneficial scenarios.",
  },
];

const ContentDiv = (props) => {
  const content = props.content;
  return (
    <div>
      <h3>{content.title} </h3>
      <hr className="mt-1" />
      <p className="mt-1" align="justify" style={{ lineHeight: 2 }}>
        {content.description}
      </p>
    </div>
  );
};
const Consulting = () => {
  return (
    <section id="personal-tax-service">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Personal Tax</h2>
        </div>
        <div className="row gy-5">
          <p align="justify" style={{ lineHeight: 2 }}>
            Each year, individuals across the United States dread the thought of
            having to file and submit all the forms necessary for their tax
            return. Elton M. Reneau & Company can alleviate all those worries
            and headaches.
          </p>

          {topicsContent.map((topic) => {
            return <ContentDiv content={topic} key={Math.random()} />;
          })}
        </div>
      </div>
    </section>
  );
};
const ServicesTaxPersonal = () => {
  Headings.services.pageNav = "Personal Tax";
  const [content] = SeoContent.pages.filter(
    (page) => page.page === "/services-personal-tax"
  );
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.services} />
      <Consulting />
    </>
  );
};

export default ServicesTaxPersonal;
