import React from "react";
import { CardMedia, Card, CardActionArea } from "@mui/material";

import { ImageContent } from "../jsComponents/ContentData";
const Certificates = ImageContent.Certificates;

const Certifications = () => {
  return (
    <section id="certifications" className="certifications">
      <div className="container" data-aos="fade-up">
        <div className="row justify-content-center">
          <div className="section-header">
            <h2>Certifications</h2>
          </div>
          <div className="row justify-content-center gap-5">
            {Certificates.map((certificate) => {
              return (
                <Card
                  key={Math.random(0, 9)}
                  sx={{ maxWidth: 250, boxShadow: "none" }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      sx={{ height: "18rem", objectFit: "contain" }}
                      image={certificate.img}
                      alt={certificate.alt}
                    />
                  </CardActionArea>
                </Card>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certifications;
