import React from "react";

import "./CallToAction.css";

const CallToAction = () => {
  return (
    <section id="call-to-action" className="call-to-action">
      <div className="container" data-aos="fade-up">
        <div className="row justify-content-center">
          <div className="col-lg-6 text-center">
            <h3>
            Schedule a Consultation with our Experienced CPAs!
            </h3>
            <a
              href="https://outlook.office365.com/owa/calendar/EltonMReneauCoPC1@reneaucpa.com/bookings/"
              target="_blank"
              rel="noopener noreferrer"
              className="subscribe-btn acuity-embed-button cta-btn"
            >
              Schedule Appointment
            </a>
            <link
              rel="stylesheet"
              href="https://embed.acuityscheduling.com/embed/button/29165218.css"
              id="acuity-button-styles"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
