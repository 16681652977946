/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import MainJS from "./jsComponents/MainJS";

import SocialMediaIcons from "./Sections/SocialMediaIcons";

//Sohw Confirm Imports
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ShowConfirm from "./Sections/ShowConfirm";
import { Zoom, Tooltip, Typography } from "@mui/material";

const Header = () => {
  //Show Confirms Hooks & Functions
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [CLIStatus, setCLIStatus] = React.useState(false);
  const onClickHandle = (popupType) => {
    if (popupType === "ShareFile") {
      setCLIStatus(true);
    } else if (popupType === "FileDrop") {
      setCLIStatus(false);
    }
    setOpen(true);
    setScroll();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(false);
    window.open(
      "https://eltonreneau.sharefile.com/share/filedrop/dx7df445-4896-46d0-a69b-f4553c2e47c2",
      "_blank"
    );
  };
  const handleOpenShareFile = () => {
    window.open("https://eltonreneau.sharefile.com", "_blank");
    setOpen(false);
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  //---------

  const ActiveTeamPaths = ["/team", "/teamProfile"];
  const ActiveServicesPaths = [
    "/services",
    "/services-tax",
    "/services-personal-tax",
    "/services-business-tax",
    "/services-accounting",
    "/services-bookkeeping",
    "/services-consulting",
  ];
  const ActiveResourcesPaths = [
    "/tax-center",
    "/useful-links",
    "/forms-instructions",
  ];

  //Nav Bar Mobile View Toggler
  const [navbarMobile, setNavbarMobile] = useState(false);
  const [navMenuResourcesDropDown, setNavMenuResourcesDropDown] =
    useState(false);
  navbarMobile
    ? document.body.classList.add("mobile-nav-active")
    : document.body.classList.remove("mobile-nav-active");

  //sticking nav bar
  useEffect(() => {
    const handleScroll = () => {
      const selectHeader = document.querySelector("#header");
      if (selectHeader) {
        window.scrollY > 100
          ? selectHeader.classList.add("sticked")
          : selectHeader.classList.remove("sticked");
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* <ShowConfirm
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        descriptionElement={descriptionElementRef}
      /> */}

      <header
        id="header"
        onScroll={MainJS.scrollTop}
        className="header d-flex align-items-center fixed-top"
      >
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
          <Link to="/" className="logo d-flex align-items-center">
            <h1 className="d-flex align-items-center">
              Elton M. Reneau & Co., PC
            </h1>
          </Link>
          {/* <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
        <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i> */}
          <i
            onClick={() => setNavbarMobile(true)}
            className={`mobile-nav-toggle mobile-nav-show bi bi-list ${navbarMobile ? "d-none" : ""
              }`}
          ></i>
          <i
            onClick={() => setNavbarMobile(false)}
            className={`mobile-nav-toggle ${navbarMobile ? "mobile-nav-hide" : "mobile-nav-show d-none"
              } bi bi-x`}
          ></i>
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <NavLink to="/" onClick={() => setNavbarMobile(false)}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" onClick={() => setNavbarMobile(false)}>
                  About
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  onClick={() => setNavbarMobile(false)}
                  className={`${ActiveServicesPaths.includes(window.location.pathname) &&
                    "active"
                    }`}
                >
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/team"
                  onClick={() => setNavbarMobile(false)}
                  className={`${ActiveTeamPaths.includes(window.location.pathname) &&
                    "active"
                    }`}
                >
                  Team
                </NavLink>
              </li>
              <li className="dropdown">
                <Link
                  to="#"
                  id="resources_link"
                  onClick={() => {
                    navMenuResourcesDropDown
                      ? setNavMenuResourcesDropDown(false)
                      : setNavMenuResourcesDropDown(true);
                  }}
                  className={`${navMenuResourcesDropDown ? "active" : ""} ${ActiveResourcesPaths.includes(window.location.pathname) &&
                    "active"
                    }`}
                >
                  <span>Resources</span>{" "}
                  <i
                    className={`bi dropdown-indicator ${navMenuResourcesDropDown
                        ? "bi-chevron-up"
                        : "bi-chevron-down"
                      }`}
                  ></i>
                </Link>
                <ul
                  className={`${navMenuResourcesDropDown ? "dropdown-active" : ""
                    }`}
                >
                  <li>
                    <NavLink
                      to="/tax-center"
                      onClick={() => setNavbarMobile(false)}
                    >
                      Tax Center
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/useful-links"
                      onClick={() => setNavbarMobile(false)}
                    >
                      Useful Links
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/forms-instructions"
                      onClick={() => setNavbarMobile(false)}
                    >
                      Forms & Instructions
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/* <li>
              <NavLink
                id="careers-link"
                to="/careers"
                onClick={() => setNavbarMobile(false)}
              >
                Careers
              </NavLink>
            </li> */}
              <li>
                <NavLink
                  id="contact-link"
                  to="/contact"
                  onClick={() => setNavbarMobile(false)}
                >
                  Contact
                </NavLink>
              </li>
              <li>
                <Tooltip
                  sx={{ fontSize: "2rem" }}
                  TransitionComponent={Zoom}
                  title={
                    <Typography sx={{ fontSize: "0.9rem" }}>
                      This link lets visitors choose an employee and upload
                      files to their File Box.
                    </Typography>
                  }
                  arrow
                >
                  <NavLink
                    to="javascript(0)"
                    onClick={(e) => {
                      e.preventDefault();
                      onClickHandle("FileDrop");
                    }}
                  >
                    File Drop
                  </NavLink>
                </Tooltip>
              </li>
              <li className="me-4">
                <Tooltip
                  sx={{ fontSize: "2rem" }}
                  TransitionComponent={Zoom}
                  title={
                    <Typography sx={{ fontSize: "0.9rem" }}>
                      This link gives access to the documents and folders by
                      logging in to your ShareFile account.
                    </Typography>
                  }
                  arrow
                >
                  <NavLink
                    to="javascript(0)"
                    onClick={(e) => {
                      e.preventDefault();
                      onClickHandle("ShareFile");
                    }}
                  >
                    Client Login
                  </NavLink>
                </Tooltip>
              </li>

              {/* <li>
              <NavLink
                to="https://www.clover.com/pos-systems/accept-payments"
                target="_blank"
              >
                Payments
              </NavLink>
            </li> */}
              {/* </ul> */}

              <li>
                <SocialMediaIcons
                  navbarMobile={navbarMobile}
                  head={true}
                  foot={false}
                  smfoot={false}
                />
              </li>
            </ul>
          </nav>
        </div>
      </header>
      
      <ShowConfirm
        content={
          CLIStatus ? (
            <span>
              By clicking <strong>OPEN</strong> Button will Securely Redirecting
              to the Elton's <strong>ShareFile Client Portal</strong>.
            </span>
          ) : (
            <span>
              if you are new to <strong>File Drop</strong>, please read the
              Instructions before proceeding.
            </span>
          )
        }
        CLIStatus={CLIStatus}
        open={open}
        fullScreen={fullScreen}
        handleClose={handleClose}
        handleOpen={CLIStatus ? handleOpenShareFile : handleOpen}
        scroll={scroll}
        descriptionElement={descriptionElementRef}
      />
      {/* <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {/* Dialog content */}
      {/* <DialogTitle>Attention</DialogTitle>
        <DialogContent>
          <ShowConfirm />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="medium" onClick={handleOpen}>
            OPEN
          </Button>
          <Button onClick={handleClose}>CLOSE</Button>
        </DialogActions> */}
      {/* </Dialog> */}
    </>
  );
};

export default Header;
