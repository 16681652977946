import React from "react";
import { NavLink } from "react-router-dom";
// Components
import Breadcrumbs from "../../Breadcrumbs";
import MetaHelmet from "../../SEO/MetaHelmet";
// Content
import { Headings, PagesData } from "../../jsComponents/ContentData";
import SeoContent from "../../SEO/SeoContent";

const listData = PagesData.usefulLinks;
const Li = (props) => {
  return (
    <li style={{ paddingBottom: "1rem" }}>
      <NavLink to={props.li.link} target="_blank">
        {props.li.value}
      </NavLink>
    </li>
  );
};
const Content = () => {
  return (
    <section id="personal-tax-service">
      <div className="container" data-aos="fade-up">
        <div className="row gy-5">
          <h3>Useful Links </h3>
          <hr className="mt-1" />
          <p className="mt-1" align="justify" style={{ lineHeight: 2 }}>
            In a world where information is abundant, useful links are a vital
            asset to any online experience. Whether you're conducting research,
            completing tasks, or learning new skills, our curated selection of
            useful links can help you achieve your goals more efficiently.
          </p>
          <div className="row">
            <ul style={{ paddingLeft: "3rem" }}>
              {listData.map((li) => {
                return <Li key={li.key} li={li} />;
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
const UsefulLinks = () => {
  const [content] = SeoContent.pages.filter(
    (page) => page.page === "/useful-links"
  );
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.usefulLinks} />
      <Content />
    </>
  );
};
export default UsefulLinks;
