/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
// Components
import Breadcrumbs from "../../Breadcrumbs";
import MetaHelmet from "../../SEO/MetaHelmet";
// Content
import { Headings } from "../../jsComponents/ContentData";
import SeoContent from "../../SEO/SeoContent";

const Content = () => {
  return (
    <section id="contact" className="contact">
      <div className="container position-relative" data-aos="fade-up">
        <div className="row gy-4 d-flex justify-content-end">
          <div className="section-header mb-0 pb-0">
            <h2>Tax Rates</h2>
          </div>
          <div className="text-center">
            <p>
              Explore the tax rates page to find information on federal income
              tax rates, state and local taxes, and how they are affected by
              income level and filing status.
            </p>
          </div>
          <iframe
            style={{ height: "100vh", width: "100vw" }}
            src={`${process.env.PUBLIC_URL}/taxrates.html`}
          ></iframe>
        </div>
      </div>
    </section>
  );
};
const TaxCenterRates = () => {
  Headings.taxCenter.pageNav = "Tax Rates";
  const [content] = SeoContent.pages.filter(
    (page) => page.page === "/tax-rates"
  );
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.taxCenter} />
      <Content />
    </>
  );
};

export default TaxCenterRates;
