import React from "react";
import { NavLink } from "react-router-dom";

const Breadcrumbs = (props) => {
  const services = [
    "/services-tax",
    "/services-accounting",
    "/services-consulting",
    "/services-personal-tax",
    "/services-business-tax",
    "/services-bookkeeping",
    "/services-cfo",
    "/services-rei",
    "/services-nptax",
    "/services-boi",
  ];
  const servicesPersonalTax = [
    "/services-personal-tax",
    "/services-business-tax",
  ];

  return (
    <div
      className="breadcrumbs d-flex align-items-center"
      // Header Image form props
      style={{ backgroundImage: `url(${props.content.image})` }}
    >
      <div className="container position-relative d-flex flex-column align-items-center">
        {/* Title */}
        <h2>{props.content.pageName}</h2>
        <ol>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          {/* Team Navigation Link Added if teamProfile Page Open */}
          {window.location.pathname === "/teamProfile" && (
            <>
              <li>
                <NavLink to="/team">Team</NavLink>
              </li>
            </>
          )}
          {/* Services */}
          {services.includes(window.location.pathname) && (
            <>
              <li>
                <NavLink to="/services">Services</NavLink>
              </li>
              {/* Personal Tax  */}
              {servicesPersonalTax.includes(window.location.pathname) && (
                <li>
                  <NavLink to="/services-tax">Tax Services</NavLink>
                </li>
              )}
              {/* Accounting  */}
              {window.location.pathname === "/services-bookkeeping" && (
                <li>
                  <NavLink to="/services-accounting">
                    Accounting Services
                  </NavLink>
                </li>
              )}
            </>
          )}
          {/* Resources - Tax Center */}
          {window.location.pathname === "/tax-rates" && (
            <>
              <li>
                <NavLink to="/tax-center">Tax Center</NavLink>
              </li>
            </>
          )}

          {/* Page Name on Bottom Navigation*/}
          <li>{props.content.pageNav}</li>
        </ol>
      </div>
    </div>
  );
};

export default Breadcrumbs;
