//Imports
// import ReactHtmlParser from 'react-html-parser';
import * as React from "react";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Grid, Typography } from "@mui/material";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';


import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

//Components
import Breadcrumbs from "../../Breadcrumbs";
import MetaHelmet from "../../SEO/MetaHelmet";
//Content
import { Headings } from "../../jsComponents/ContentData";
import SeoContent from "../../SEO/SeoContent";
import { NavLink } from "react-router-dom";

const BOIParas = [
  "Beneficial Owner Information (BOI) refers to the identification and documentation of individuals who hold significant ownership or control over a business entity. These individuals, known as beneficial owners, are typically those who owns the company’s equity or have a substantial influence over decision-making processes.",
  "The purpose of collecting BOI is to ensure transparency, prevent fraud, and help comply with regulations related to anti-money laundering (AML), combating the financing of terrorism (CFT), and other financial crimes. Many governments and financial institutions require BOI disclosures to enhance corporate governance and prevent misuse of legal entities for illegal activities.",
];
const BOIServicesList = [
  "Every corporation or entity (LLC, S-Corporation, etc) that was created by filing a document with the Secretary of State or similar office is required to submit Beneficial Ownership Information.",
  "Reporting entities doing business before January 1st, 2024, will have until January 1st, 2025, to report their initial beneficial ownership report.",
  "Reporting entities that begin doing business between January 1st, 2024, and December 31st, 2024, will have 90 calendar days after public notice of their registration being in effect to file their initial report.",
  "Reporting entities that begin doing business after January 1st, 2025 will have 30 calendar days after their registration being in effect to file their initial report.",
  "Failure to report an initial BOI or correct false information on a previous report within 90 days of the deadline can result in a $500/ day fine and up to two years imprisonment with a $10,000 fine.",
  "In the unusual situation where a business did not file with the secretary of state or similar office, the entity is not required to submit a BOI.",
  {
    heading: "Beneficial Owner",
    description: "Any Individual who :",
    list: ["Exercises substantial control over a company.",
      "Owns or controls at least 25% of a company's ownership interest."
    ]
  },
  {
    heading: "Substantial control",
    description: "Can be exercised in four ways :",
    list: ["Individual is a senior officer.",
      "The individual has the power to appoint or remove certain officers or the majority of directors.",
      "The individual is an important decision-maker within the company.",
      "Any other form of substantial control (flexible corporate structures)."
    ]
  },
  {
    heading: "Ownership interest",
    description: "It can be defined in a few ways :",
    list: [
      "Equity, stock, or voting rights",
      "Capital or profit interest",
      "Convertible instruments: anything that can be converted into the above-listed interests."]
  },
  {
    heading: "Exceptions of beneficial owners",
    description: "",
    list: ['Beneficiary is a minor in which case the information of their parent or guardian would be reported.',
      'The individual acts on behalf of the actual beneficial owner (agent, custodian, advisor, etc). Here the information of the actual beneficial owner will be reported.',
      "The individual is an employee of the beneficial owner(can't be a senior officer, can be terminated, and is subject to direct control by the employer). ",
      "The individual’s only interest in the company is in the future as an inheritor (once the individual inherits the company this exception no longer applies). ",
      "The individual is a creditor of the company.",
    ]
  },
  `Entities are only required to report company applicants if they are created and registered on or after January 1st, 2024.`,
  'All company applicants must be listed as individuals, not companies or legal entities.',
  {
    heading: "Information necessary to be collected from a reporting company",
    description: "Full legal name, trade name/ DBA name, complete current address, state, tribal, or foreign jurisdiction of formation, IRS TIN & EIN.",
    list: []
  },
  {
    heading: "Special reporting rules",
    description: "",
    list: ["If owned by an exempt entity, it is not necessary to report the information of a beneficial owner.",
      "If owned by a minor child one does not need to report their information provided you have provided the necessary information of their parent or guardian.",
      "Foreign pooled investment vehicle: you do not need to report the information of each beneficial owner if the company was formed under foreign law, you must only report the information of the owner with the greatest power.",
      "Company applicants reporting for companies formed before January 1st, 2024 do not have to report any company applicants."]
  },
  {
    heading: "FinCEN Identifier",
    description: "Not mandatory, a company applicant or beneficial owner can request a FinCEN identifier after they submit their initial report to FinCEN, this can be used in the future in place of the four pieces of personal information.",
    list: []
  },
];
const FAQ = [
  {
    heading: "What to do if previously reported information changes?",
    description: "Companies must report any changes to required information about the company or beneficial owner within 30 days of the change.",
    list: []
  },
  {
    heading: "What if an inaccuracy is detected in the information?",
    description: "",
    list: [
      "If there is an inaccuracy in the reported information, the company must correct the mistake within 30 days of being made aware of the inaccuracy, the same rule applies to submitting a FinCEN application.",
      "(there is no penalty for an inaccurate BOI report provided it is corrected within 90 days of submission)"
    ]
  },
  {
    heading: "What if a company is made exempt after already filing a report?",
    description: "The company should file an updated BOI report indicating that it is newly exempt.",
    list: []
  },
  {
    heading: "What is the purpose of the Beneficial Ownership Information FIling?",
    description: "Created to deter fraudulent activity (shell companies/ unclear ownership structures), beneficial ownership information is reported to shine a light on the individuals who indirectly or directly control an entity.",
    list: []
  },
  {
    heading: "Who is exempt from filing Beneficial Ownership Information?",
    description: "",
    list: ["Securities reporting issuer",
      "Governmental authority",
      "Bank",
      "Credit union",
      "Depository institution holding company",
      "Money services business",
      "Broker or dealer in securities",
      "Securities exchange or clearing agency",
      "Other Exchange Act registered entity",
      "An investment company or investment adviser",
      "Venture capital fund adviser",
      "Insurance company",
      "State-licensed insurance producer",
      "Commodity Exchange Act registered entity",
      "Accounting firm",
      "Public utility",
      "Financial market utility",
      "Pooled investment vehicle",
      "Tax-exempt entity",
      "Entity assisting a tax-exempt entity",
      "Large operating company",
      "Subsidiary of certain exempt entities",
      "Inactive entity"]
  },
]

//Accordion Sources
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,  
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // border: `1px solid ${theme.palette.divider}`,
  // backgroundColor: 'rgba(0, 0, 0, .03)',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


//Table Component
const columns = [
  { id: 'eno', label: 'Exemption No.', minWidth: 170 },
  { id: 'est', label: 'Exemption Short Title', minWidth: 100 },
];

function createData(eno, est) {
  return { eno, est };
}
export function DataTable(props) {
  const rowsData = props.data;
  let rows = rowsData.map((row, index) => createData(index + 1, row));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={`colu${column.id}`}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor: 'green', color: 'white', fontWeight: 'bold' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={`row${rowIndex}`}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={`col${column.id}`} align={column.align} sx={{ textAlign: column.id === 'eno' && 'center' }}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
//End Of Table Component

const BOIContent = () => {
  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const listItemExpandable = (item, key, index) => {
    let isTable = item.heading ? item.heading === "Who is exempt from filing Beneficial Ownership Information?" : false;
    return <>
      <Accordion key={key} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
        <AccordionSummary aria-controls={`${index}d-content`} id={`${index}d-header`}>
          <Typography variant="text" sx={{ fontWeight: 'bold' }}>{item.heading}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {item.description && typeof (item.description) === 'string' ? item.description : ''}
          </Typography>
          {(!isTable && item.list) && <ol>{item.list.map((sli, index) => <li key={`$sli${index}`}>{sli}</li>)}</ol>}
          {isTable && <DataTable data={item.list} />}
        </AccordionDetails>
      </Accordion>
    </>;
  }
  return (
    <section id="personal-tax-service" style={{ paddingBottom: '2rem' }}>
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Beneficial Owner Information (BOI)</h2>
        </div>
        <div className="row gy-1" style={{ paddingBottom: '3rem' }}>
          {BOIParas.map((para) => {
            return (
              <p align="justify" style={{ lineHeight: 2 }} key={Math.random()}>
                &ensp;&ensp;{para}
              </p>
            );
          })}
          <Grid container>
            <p
              align="justify"
              style={{ lineHeight: 2, fontWeight: "bold", fontSize: "1.3rem" }}
            >
              Beneficial Owner Information Guidelines ...
            </p>
            <br />
            <ul className="mt-2 ps-5" style={{ lineHeight: 2 }}>
              {BOIServicesList.map((li, index) => {
                return (
                  <li key={Math.random()}>
                    {typeof (li) === 'string' ? li : //return li string
                      typeof (li) === 'object' ?
                        listItemExpandable(li, Math.random(), index) : ''}
                    {/* (<><strong>{li.heading} : </strong>{li.description && typeof (li.description) === 'string' ? (<><br />{li.description}</>) : ''}
                        <ol>{li.list.map(sli => listItem(sli, Math.random()))}</ol>
                      </>)                      
                       : ""} */}
                  </li>);
              })}
              {/* and more... */}
            </ul>
          </Grid>
          <Grid container>
            <p
              align="justify"
              style={{ lineHeight: 2, fontWeight: "bold", fontSize: "1.3rem" }}
            >
              FAQs on Beneficial Owner Information...
            </p>
            <br />
            {/* <ul className="mt-2 ps-5" style={{ lineHeight: 2 }}> */}
            {FAQ.map((li, index) => {
              return (
                <span key={Math.random()}>
                  {typeof (li) === 'string' ? li : //return li string
                    typeof (li) === 'object' ?
                      listItemExpandable(li, Math.random(), index) : ''}
                  {/* (<><strong>{li.heading} : </strong>{li.description && typeof (li.description) === 'string' ? (<><br />{li.description}</>) : ''}
                        <ol>{li.list.map(sli => listItem(sli, Math.random()))}</ol>
                      </>)                      
                       : ""} */}
                </span>);
            })}
            {/* and more... */}

            {/* </ul> */}
          </Grid>
        </div>
        <Grid container sx={{ paddingTop: '1rem' }}>
          <Typography variant="h3" align="justify"
            style={{ lineHeight: 2, fontWeight: "bold", fontSize: "1.3rem" }}>
            For More Information Please <NavLink to='/contact?subject=beneficial-owner-information-(boi)'>Contact Us</NavLink>
          </Typography>
        </Grid>
      </div>
    </section>
  );
};

export default function ServicesBOI() {
  Headings.services.pageNav = "Beneficial Ownership Information (BOI)";
  const [content] = SeoContent.pages.filter(
    (page) => page.page === "/services-boi"
  );
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.services} />;
      <BOIContent />
    </>
  );
}