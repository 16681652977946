import React from "react";

const AboutContent = (props) => {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="row gy-4" data-aos="fade-up">
          <div className="col-lg-4">
            {/* About Content Image */}
            <img src={props.pageContent.image} className="img-fluid" alt="" />
          </div>
          <div className="col-lg-8">
            <div className="content ps-lg-5">
              {/* Title */}
              <h3>{props.pageContent.title}</h3>
              {/* description */}
              <p style={{ textAlign: "justify" }}>
                {props.pageContent.description}
              </p>
              <ul>
                {/* Paragraphs over iteration */}
                {props.pageContent.paragraphs.map((paragraph) => {
                  return (
                    <li key={paragraph.key}>
                      <i className="bi bi-check-circle-fill"></i>
                      <span style={{ textAlign: "justify" }}>
                        {paragraph.text}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutContent;
