import React from "react";
import { NavLink } from "react-router-dom";

// Components
import Breadcrumbs from "../../Breadcrumbs";
import MetaHelmet from "../../SEO/MetaHelmet";
// Content
import { Headings } from "../../jsComponents/ContentData";
import SeoContent from "../../SEO/SeoContent";

const content = {
  para: "Providing financial information to our clients in a timely and accurate manner is a commitment we feel cannot be compromised. Meaningful, well-organized financial records ensure that your business operations will run more efficiently daily. Our firm provides a full range of cost-effective accounting services including the following:",
  needs: ['A compilation is based on client information provided and used primarily for internal use.',
    'General ledger & financial statement preparation',
    'Bookkeeping (monthly/quarterly/annually)',
    'Accounting system setup for new businesses',
    'Preparation of sales tax and property renditions',
    'Personal financial statements',
    'Payroll, Full or Customized']
}

const Accounting = () => {
  return (
    <section id="personal-tax-service">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Accounting Services</h2>
        </div>
        <div className="row gy-5">
          <p align="justify" style={{ lineHeight: 2 }}>
            {content.para}
          </p>
          <br />
          <ul className="mt-2 ps-5" style={{ lineHeight: 2 }}>
            {content.needs.map((need) => (
              <li key={Math.random()}>{need}</li>
            ))}
          </ul>
          <p>
            More Details About{" "}
            <NavLink to="/services-bookkeeping">Bookkeeping</NavLink>.
          </p>
        </div>
      </div>
    </section>
  );
};
const ServicesAccounting = () => {
  Headings.services.pageNav = "Accounting Services";
  const [content] = SeoContent.pages.filter(
    (page) => page.page === "/services-accounting"
  );
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.services} />
      <Accounting />
    </>
  );
};

export default ServicesAccounting;
