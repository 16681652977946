import { useSearchParams } from 'react-router-dom';

import * as React from 'react';
import { Card, Typography, CardActions, CardContent, CardMedia } from '@mui/material';
// import Button from '@mui/material/Button';



import Breadcrumbs from "../Breadcrumbs";

import { Headings, News as Posts } from "../jsComponents/ContentData";
import MetaHelmet from '../SEO/MetaHelmet';

// Fetch the Post from Content Data
const getPost = (postKey) => {
  let post = Posts.find(post => post.title === postKey);
  if (!post) post = Posts[0];
  return post
}

export function PostImageCard(props) {
  const post = props.post;
  return (
    <Card sx={{ maxWidth: 1, maxHeight: 1 / 2 }}>
      <CardMedia
        component="img"
        alt="green iguana"
        // image="/static/images/cards/contemplative-reptile.jpg"
        image={post.img}
      />
      <CardContent>
        {/* <Typography gutterBottom variant="h5" component="div">
          Lizard
        </Typography> */}
        <Typography variant="body2" color="text.secondary">{post.description}
        </Typography>
      </CardContent>
      <CardActions>
        {/* <Button size="small">Share</Button> */}
        {/* <Button variant='contained' size="small">Contact For Assistance</Button> */}
      </CardActions>
    </Card>
  );
}

const Content = (post) => {
  post = post.post;
  let title = post ? post.title : 'Post Not Found';
  let description = post ? (post.title === "Post Not Found" ? '' : post.description) : '';
  let newsPost = { ...post, title, description }

  return (
    <section id="news">
      <div className="container d-flex flex-column align-items-center" data-aos="fade-up">
        <div className='col-md-7' style={{ textAlign: 'left', paddingBottom: '30px' }}>
          <h1>{title}</h1>
          <br />
          <Typography sx={{ fontFamily: 'monospace' }} variant='text'>Published On : {post.date}</Typography>
          <br />
          <Typography sx={{ fontFamily: 'monospace' }} variant='text'>{post.category}</Typography>
        </div>
        <div className='col-md-7'>
          <PostImageCard post={newsPost} />
          {/* <br />
          <Typography variant="text">{description}</Typography> */}
        </div>
      </div>
    </section>
  );
};

export default function News() {
  const [searchParams] = useSearchParams();
  const postKey = searchParams.get('post');
  const post = getPost(postKey);

  Headings.contact.pageName = "News";
  Headings.contact.pageNav = "News";
  return (
    <>
      <MetaHelmet seo={post} />
      <Breadcrumbs content={Headings.contact} />;
      <Content post={post} />
    </>
  );
}
