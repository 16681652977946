import React from "react";
import { NavLink } from "react-router-dom";
import { PagesData } from "../jsComponents/ContentData";

const Card = (props) => {
  const serv = props.data;
  return (
    <div
      // Component ID
      key={serv.serviceId}
      className="col-lg-4 col-md-6 service-item d-flex"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <div className="icon flex-shrink-0">
        <i
          // Icon Style
          className={`bi ${serv.icon.iconStyle}`}
          // Icon Color
          style={{ color: serv.icon.color }}
        ></i>
      </div>
      <div>
        <h4 className="title">
          {/* Navigation */}
          <NavLink to={"/" + serv.nav} className="stretched-link">
            {/* title */}
            {serv.title}
          </NavLink>
        </h4>
        <p className="description" style={{ textAlign: "justify" }}>
          {/* Description */ serv.description}
        </p>
      </div>
    </div>
  );
};
const ServicesList = () => {
  const ServicesData = PagesData.services;
  return (
    <section id="services-list" className="services-list">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Our Services</h2>
        </div>
        <div className="row gy-5">
          {ServicesData.map((serv) => {
            return <Card key={serv.serviceId} data={serv} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default ServicesList;
