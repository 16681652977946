import React from "react";
import { Helmet } from "react-helmet";

const MetaHelmet = (props) => {
  const seoContent = props.seo;
  const description = seoContent.description;
  const title = seoContent.title;
  const keywords = seoContent.keywords.join();
  const url = seoContent.url;

  return (
    <Helmet>
      <title>{title} | Elton M. Reneau & Co., PC</title>
      <link rel="canonical" href={url} />
      <link rel="alternate" href={url} hreflang="en-us" />

      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />

      {/* Twitter Card Meta Tags  */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

export default MetaHelmet;
