const SeoContent = {
  pages: [
    {
      page: "/",
      title: "Home",
      description:
        "Elton M. Reneau & Co., PC is a trusted CPA firm providing comprehensive accounting, tax, and financial services to individuals and businesses. Contact us for expert advice and personalized solutions.",
      keywords: [
        "financial information",
        "balance sheet",
        "tax return",
        "income statement",
        "general ledger",
        "accounting software",
        "accounts receivable",
        "journal entries",
        "financial reports",
        "financial position",
        "small business",
        "accounts payable",
        "bookkeeping software",
        "bookkeepers and accountants",
        "financial data",
        "bookkeepers record",
        "profit and loss",
        "cash flow statements",
        "company financial transactions",
        "income and expenses",
        "elton reneau cpa",
        "accountant",
        "accounting services new braunfels",
        "best bookkeeper in new braunfels tx",
        "best local accountants",
        "bookkeeping services new braunfels",
        "certified public accountant",
        "cpa new braunfels",
        "tax services new braunfels",
        "tax preparers near me",
        "tax preparers in New Braunfels",
        "Consulting Services",
        "OutSourced CFO Services",
        "Real Estate Investing Services",
        "Non Profit Tax Services",
        "financial services",
        "Elton M. Reneau & Co., PC",
        "Bookkeeping",
        "Bookkeeping Services",
        "CPA in New Braunfels",
        "CPA in Texas",
        "accounting firms san antonio",
        "tax preparation firms san antonio",
        "Consulting firms san antonio",
        "Real Estate Investing Service firms",
        "Certified QuickBooks Online ProAdvisor",
        "Certified QuickBooks ProAdvisor Desktop",
        "QuickBooks Online ProAdvisor",
        "QuickBooks Desktop ProAdvisor",
        "QuickBooks Online certification",
        "QuickBooks Desktop certification",
        "QuickBooks ProAdvisor services",
        "QuickBooks expert assistance",
        "Professional QuickBooks support",
        "QuickBooks accounting services",
        "QuickBooks bookkeeping services",
        "QuickBooks setup and training",
        "Certified QuickBooks experts",
        "QuickBooks Online and Desktop ProAdvisor",
        "QuickBooks certified professionals",
      ],
      url: "https://reneaucpa.com",
    },
    {
      page: "/about",
      title: "About Us",
      description:
        "Learn more about Elton Reneau CPA, our mission, vision, and the team of experts providing top-notch tax, accounting, consulting, outsourced CFO, real estate investing, and non-profit tax services.",
      keywords: [
        "about us",
        "about Elton Reneau CPA",
        "Elton Reneau CPA information",
        " mission",
        "vision",
        "reneaucpa team",
        "Elton Reneau CPA team",
      ],
      url: "https://reneaucpa.com/about",
    },
    {
      page: "/services",
      title: "Our Services",
      description:
        "Explore the range of services offered by Elton M. Reneau & Co., PC, including tax services, accounting services, consulting services, outsourced CFO services, real estate investing services, and non-profit tax services.",
      keywords: [
        "Elton M. Reneau & Co., PC Services",
        "reneacpa services",
        "tax services",
        "accounting services",
        "consulting services",
        "outsourced CFO services",
        "real estate investing",
        "non-profit tax services",
      ],
      url: "https://reneaucpa.com/services",
    },
    {
      page: "/services-tax",
      title: "Tax Services",
      description:
        "Get professional tax services in New Braunfels, TX with Elton Reneau CPA. Maximize your returns and ensure compliance. Contact us today!",
      keywords: [
        "tax services",
        "tax planning",
        "tax preparation",
        "Personal Tax",
        "Business Tax",
        "tax filing",
        "Corporations",
        "Partnerships",
        "Limited Liability Companies",
        "Trusts & Estates",
        "Exempt Organizations",
        "Sole Proprietorships",
        "State tax",
        "Individual",
        "New Braunfels",
        "CPA",
        "Elton M. Reneau & Co., PC",
      ],
      url: "https://reneaucpa.com/services-tax",
    },
    {
      page: "/services-personal-tax",
      title: "Personal Tax Services",
      description:
        "Get expert personal tax services in New Braunfels, TX with Elton Reneau CPA. Maximize your tax returns and ensure compliance. Contact us today!",
      keywords: [
        "personal tax services",
        "1040 Preparation",
        "Gift and Estate",
        "Year-End Tax Planning",
        "tax services",
        "tax preparation",
        "Personal Tax",
        "tax filing",
        "Individual",
        "New Braunfels",
        "CPA",
        "Elton M. Reneau & Co., PC",
      ],
      url: "https://reneaucpa.com/services-personal-tax",
    },
    {
      page: "/services-business-tax",
      title: "Business Tax Services",
      description:
        "Professional business tax services in New Braunfels, TX with Elton Reneau CPA. Ensure compliance and optimize your tax strategy. Contact us today!",
      keywords: [
        "business tax services",
        "corporate tax",
        "C-Corp",
        "S-Corp",
        "Partnership",
        "Not For Profit Organizations",
        "tax strategy",
        "New Braunfels",
        "CPA",
        "Elton M. Reneau & Co., PC",
      ],
      url: "https://reneaucpa.com/services-business-tax",
    },
    {
      page: "/services-accounting",
      title: "Accounting Services",
      description:
        "Comprehensive accounting services in New Braunfels, TX with Elton Reneau CPA. Keep your finances in order with our expert services. Contact us now!",
      keywords: [
        "accounting services",
        "General ledger & financial statement preparation",
        "sales tax and property renditions",
        "Personal financial statements",
        "financial planning",
        "auditing",
        "New Braunfels",
        "CPA",
        "Elton Reneau",
        "Elton M. Reneau & Co., PC",
      ],
      url: "https://reneaucpa.com/services-accounting",
    },
    {
      page: "/services-bookkeeping",
      title: "Bookkeeping  Services",
      description:
        "Reliable bookkeeping services in New Braunfels, TX with Elton Reneau CPA. Keep your financial records accurate and up-to-date. Contact us today!",
      keywords: [
        "accounting services",
        "bookkeeping services",
        "bookkeeping",
        "financial records",
        "Business Consultation",
        "New Start-Up Planning",
        "Payroll, Full or Customized",
        "Compilations",
        "Financial Statements",
        "Form 1099 Preparation",
        "W-2 Preparation",
        "QuickBooks Set Up and Training",
        "Sales tax service",
        "Personal Financial Statements",
        "New Braunfels",
        "CPA",
        "Elton Reneau",
        "Elton M. Reneau & Co., PC",
      ],
      url: "https://reneaucpa.com/services-accounting",
    },
    {
      page: "/services-consulting",
      title: "Consulting Services",
      description:
        "Expert consulting services in New Braunfels, TX with Elton Reneau CPA. Enhance your business strategy with our professional advice. Contact us today!",
      keywords: [
        "consulting services",
        "consulting services",
        "business consulting",
        "Quickbooks Consulting",
        "financial consulting",
        "New Braunfels",
        "CPA",
        "Elton Reneau",
        "Elton M. Reneau & Co., PC",
      ],
      url: "https://reneaucpa.com/services-consulting",
    },
    {
      page: "/services-cfo",
      title: "Outsourced CFO Services",
      description:
        "Reliable outsourced CFO services in New Braunfels, TX with Elton Reneau CPA. Strategic financial leadership for your business. Contact us now!",
      keywords: [
        "outsourced CFO services",
        "financial leadership",
        "strategic planning",
        "New Braunfels",
        "CPA",
        "Elton Reneau",
        "Elton M. Reneau & Co., PC",
      ],
      url: "https://reneaucpa.com/services-cfo",
    },
    {
      page: "/services-rei",
      title: "Real Estate Investing Services",
      description:
        "Expert real estate investing services in New Braunfels, TX with Elton Reneau CPA. Maximize your property investments. Contact us today!",
      keywords: [
        "real estate investing services",
        "property investment",
        "New Braunfels",
        "CPA",
        "Elton Reneau",
        "Elton M. Reneau & Co., PC",
      ],
      url: "https://reneaucpa.com/services-rei",
    },
    {
      page: "/services-nptax",
      title: "Non-Profit Tax Services",
      description:
        "Comprehensive non-profit tax services in New Braunfels, TX with Elton Reneau CPA. Ensure compliance and maximize your impact. Contact us today!",
      keywords: [
        "non-profit tax services",
        "tax compliance",
        "non-profit organization",
        "filing Form 990",
        "State and local tax filings",
        "Compliance with IRS guidelines",
        "Tax planning strategies",
        "Tax-saving opportunities",
        "Financial transparency and accountability",
        "New Braunfels",
        "CPA",
        "Elton Reneau",
        "Elton M. Reneau & Co., PC",
      ],
      url: "https://reneaucpa.com/services-nptax",
    },
    {
      page: "/services-boi",
      title: "Beneficial Owner Information (BOI)",
      description:
        "Now offering Beneficial Owner Information (BOI) services to help businesses identify and verify individuals who hold significant control or ownership in a company. Ensure compliance with regulatory standards.",
      keywords: [
        "Beneficial Owner Information",
        "Boi Compliance",
        "boi complaince",
        "boi complaince filing",
        "boi information",
        "what is BOI",
        "BOI filing near by me",
        "BOI filing",
        "BOI services",
        "beneficial ownership compliance",
        "business ownership verification",
        "anti-money laundering BOI",
        "BOI reporting requirements",
        "identify beneficial owners",
        "ownership transparency",
        "BOI regulatory compliance",
        "CFT and AML compliance",
        "company beneficial owner",
        "corporate ownership structure",
        "beneficial owner disclosure",
        "business transparency services",
        "beneficial ownership rules",        
        "New Braunfels",
        "CPA",
        "Elton Reneau",
        "Elton M. Reneau & Co., PC",
      ],
      url: "https://reneaucpa.com/services-boi",
    },
    {
      page: "/team",
      title: "Our Team",
      description:
        "Meet the team of experts at Elton M. Reneau & Co., PC. Our professionals specialize in tax services, accounting services, consulting services, outsourced CFO services, real estate investing, and non-profit tax services.",
      keywords: [
        "reneaucpa experts",
        "reneaucpa professionals",
        "reneaucpa staff",
        "reneaucpa team",
        "our team",
        "employees",
        "staff",
        "professionals",
        "experts",
      ],
      url: "https://reneaucpa.com/team",
    },
    {
      page: "/tax-center",
      title: "Tax Center",
      description:
        "Access valuable tax resources and links to IRS websites and tax rate information at the Tax Center of Elton M. Reneau & Co., PC.",
      keywords: [
        "Elton M. Reneau & Co., PC Resources",
        "reneaucpa tax center",
        "tax center",
        "IRS links",
        "tax rate information",
        "tax resources",
        "New Braunfels",
        "CPA",
        "Elton Reneau",
      ],
      url: "https://reneaucpa.com/tax-center",
    },
    {
      page: "/tax-rates",
      title: "Tax Rates",
      description:
        "Stay updated with the latest tax rates from 2018 to 2024 in New Braunfels, TX. Consult Elton Reneau CPA for accurate and professional tax services.",
      keywords: [
        "tax rates",
        "2018 tax rates",
        "2019 tax rates",
        "2020 tax rates",
        "2021 tax rates",
        "2022 tax rates",
        "2023 tax rates",
        "2024 tax rates",
        "New Braunfels",
        "CPA",
        "Elton Reneau",
        "Elton M. Reneau & Co., PC Resources",
        "reneaucpa tax center",
        "tax center",
        "tax rate information",
        "tax resources",
      ],
      url: "https://reneaucpa.com/tax-rates",
    },
    {
      page: "/useful-links",
      title: "Useful Links",
      description:
        "Find useful links for tax and technical utilities at Elton M. Reneau & Co., PC's Useful Links page.",
      keywords: [
        "Elton M. Reneau & Co., PC Resources",
        "useful links",
        "tax utilities",
        "technical utilities",
        "resources",
        "New Braunfels",
        "CPA",
        "Elton Reneau",
        "Elton M. Reneau & Co., PC Resources",
      ],
      url: "https://reneaucpa.com/useful-links",
    },
    {
      page: "/forms-instructions",
      title: "Forms & Instructions",
      description:
        "Access IRS tax forms and instructions at the Forms & Instructions page of Elton M. Reneau & Co., PC.",
      keywords: [
        "Elton M. Reneau & Co., PC Resources",
        "forms and instructions",
        "IRS forms",
        "tax forms",
        "instructions",
        "Form 1040",
        "Form W-9",
        "Form 4506-T",
        "Form W-4",
        "Form-941",
        "Form-W2",
        "Form 9465",
        "form 1040-X",
        "Form 2848",
        "Form 2848",
        "Form W-7",
        "Circular 230",
      ],
      url: "https://reneaucpa.com/forms-instructions",
    },
    {
      page: "/contact",
      title: "Contact Us",
      description:
        "Get in touch with Elton M. Reneau & Co., PC for professional tax, accounting, consulting, outsourced CFO, real estate investing, and non-profit tax services. Contact us today!",
      keywords: [
        "contact us",
        "get in touch",
        "customer service",
        "support",
        "help line",
        "consult Elton M. Reneau & Co., PC",
        "contact Elton M. Reneau & Co., PC",
      ],
      url: "https://reneaucpa.com/contact",
    },
  ],
};

export default SeoContent;
