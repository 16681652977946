import React from "react";
import { NavLink } from "react-router-dom";
// Components
import Breadcrumbs from "../../Breadcrumbs";
import MetaHelmet from "../../SEO/MetaHelmet";
// Content
import { Headings } from "../../jsComponents/ContentData";
import SeoContent from "../../SEO/SeoContent";

const TaxServices = () => {
  return (
    <section id="personal-tax-service">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Tax Planning & Preparation</h2>
        </div>
        <div className="row gy-5">
          <p align="justify" style={{ lineHeight: 2 }}>
            Tax planning and preparation form a winning combination for our
            individual and business clients. Whether you are an individual or a
            multi-tiered partnership, our experienced staff can develop tax
            strategies that take advantage of new tax laws and legislation. We
            offer a wide range of tax preparation services and assistance with
            IRS compliance and correspondence for:
          </p>
          <ul className="ps-5 mt-0">
            <li>Corporations</li>
            <li>Partnerships</li>
            <li>Limited Liability Companies</li>
            <li>Trusts &amp; Estates</li>
            <li>Exempt Organizations</li>
            <li>Sole Proprietorships</li>
            <li>State tax</li>
            <li>Individual</li>
          </ul>
          <p>
            More Details About{" "}
            <NavLink to="/services-personal-tax">Personal Tax</NavLink>,{" "}
            <NavLink to="/services-business-tax">Business Tax</NavLink>
          </p>
        </div>
      </div>
    </section>
  );
};
const Service = (props) => {
  Headings.services.pageNav = "Tax Services";
  const [content] = SeoContent.pages.filter(
    (page) => page.page === "/services-tax"
  );
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.services} />
      <TaxServices />
    </>
  );
};
export default Service;
