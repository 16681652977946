import React from "react";
// Components
import Breadcrumbs from "../../Breadcrumbs";
import MetaHelmet from "../../SEO/MetaHelmet";
// Content
import { Headings } from "../../jsComponents/ContentData";
import SeoContent from "../../SEO/SeoContent";

const paraContent = [
  "As a business owner, you must wear a lot of different hats, and some of them fit better than others. Many entrepreneurs find themselves struggling with their bookkeeping responsibilities. They do not have the time, or the knowledge, or they simply do not enjoy it. At Elton M. Reneau & Company, our bookkeeping services make it possible for you to spend your time where it is best spent – on your areas of expertise and interest. We have experience in serving a wide range of industries and business models, including food service, real estate, manufacturing, construction, retail, legal, and medical and dental practices. Whether you run a factory or sell fashion jewelry, we know the services you need and can provide them in a way that suits your schedule and budget.",
  "At Elton M. Reneau & Company, our goal is to help you meet your goals, both short and long-term. We know that competent, consistent bookkeeping is the key to a business’s success. Having accurate and professionally prepared financial documents enhances your understanding of the health of your business and guides your decisions. They can provide creditors and banks with the information they need to help your business grow. Our services are customized to your specific needs, and can include any, or all, of the following:",
];
const needsList = [
  "Bookkeeping, Full or Customized",
  "Business Consultation",
  "New Start-Up Planning",
  "Payroll, Full or Customized",
  "Compilations",
  "Financial Statements",
  "Form 1099 Preparation",
  "W-2 Preparation",
  "QuickBooks Set Up and Training",
  "Sales tax service",
  "Personal Financial Statements",
];

const NeedsComoponent = (props) => {
  return (
    <>
      <p className="mt-1" align="justify" style={{ lineHeight: 2 }}>
        &ensp;&ensp;{props.content}
      </p>
      <br />
    </>
  );
};
const Accounting = () => {
  return (
    <section id="personal-tax-service">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Bookkeeping</h2>
        </div>
        <div className="row gy-5">
          <p align="justify" style={{ lineHeight: 1.8 }}></p>
          <h3>Tailored to Your Needs</h3>
          <hr className="mt-1" />
          {paraContent.map((para) => (
            <NeedsComoponent key={Math.random()} content={para} />
          ))}
          <ul className="ms-5 mt-2">
            {needsList.map((need) => (
              <li key={Math.random()}>{need}</li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
const ServicesAccountingBookkeeping = (props) => {
  Headings.services.pageNav = "Bookkeeping";
  const [content] = SeoContent.pages.filter(
    (page) => page.page === "/services-bookkeeping"
  );
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.services} />
      <Accounting />
    </>
  );
};

export default ServicesAccountingBookkeeping;
