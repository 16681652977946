import React from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Components
import Wrapper from "./Components/Sections/Wrapper";

// Pages
import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import TeamProfile from "./Components/Pages/TeamProfile";
import Services from "./Components/Pages/Services";
import ServicesTax from "./Components/Pages/Services/ServicesTax";
import ServicesAccounting from "./Components/Pages/Services/ServicesAccounting";
import ServicesConsulting from "./Components/Pages/Services/ServicesConsulting";
import ServicesTaxPersonal from "./Components/Pages/Services/ServicesTaxPersonal";
import ServicesTaxBusiness from "./Components/Pages/Services/ServicesTaxBusiness";
import ServicesAccountingBookkeeping from "./Components/Pages/Services/ServicesAccountingBookkeeping";
import ServicesCFO from "./Components/Pages/Services/ServicesCFO";
import Team from "./Components/Pages/Team";
import TaxCenter from "./Components/Pages/Resources/taxCenter";
import TaxCenterRates from "./Components/Pages/Resources/taxCenterRates";
import UsefulLinks from "./Components/Pages/Resources/usefulLinks";
import FormsInstructions from "./Components/Pages/Resources/formsInstructions";
// import Careers from "./Components/Pages/Careers";
import Contact from "./Components/Pages/Contact";
import ServicesRealEstateInvesting from "./Components/Pages/Services/ServicesRealEstateInvesting";
import ServicesNonProfitTax from "./Components/Pages/Services/ServicesNonProfitTax";
// import Captcha from "./Components/Pages/Captcha";
import Error404 from "./Components/Pages/404";
import News from "./Components/Pages/News";
import ServicesBOI from "./Components/Pages/Services/ServicesBOI";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Wrapper />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/about", element: <About /> },
        { path: "/about-us", element: <About /> },
        { path: "/teamProfile", element: <TeamProfile /> },
        // { path: "/careers", element: <Careers /> },
        { path: "/contact", element: <Contact /> },
        { path: "/services", element: <Services /> },
        { path: "/services-tax", element: <ServicesTax /> },
        { path: "/services-accounting", element: <ServicesAccounting /> },
        { path: "/services-consulting", element: <ServicesConsulting /> },
        { path: "/services-personal-tax", element: <ServicesTaxPersonal /> },
        { path: "/services-business-tax", element: <ServicesTaxBusiness /> },
        {
          path: "/services-bookkeeping",
          element: <ServicesAccountingBookkeeping />,
        },
        { path: "/services-cfo", element: <ServicesCFO /> },
        { path: "/services-rei", element: <ServicesRealEstateInvesting /> },
        { path: "/services-nptax", element: <ServicesNonProfitTax /> },
        { path: "/services-boi", element: <ServicesBOI /> },
        { path: "/team", element: <Team /> },
        { path: "/tax-center", element: <TaxCenter /> },
        { path: "/tax-rates", element: <TaxCenterRates /> },
        { path: "/useful-links", element: <UsefulLinks /> },
        { path: "/forms-instructions", element: <FormsInstructions /> },
        // { path: "/file-drop", element: <Captcha /> },
        { path: "/news", element: <News /> },
        { path: "*", element: <Error404 /> },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
