import React from "react";
// Components
import Breadcrumbs from "../Breadcrumbs";
import TeamMembers from "../Sections/TeamMembers";
import MetaHelmet from "../SEO/MetaHelmet";

// Content
import { Headings } from "../jsComponents/ContentData";
import SeoContent from "../SEO/SeoContent";

const Team = () => {
  const [content] = SeoContent.pages.filter((page) => page.page === "/team");
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.team} />
      <TeamMembers />
    </>
  );
};

export default Team;
