import React from "react";
//Components
import Breadcrumbs from "../../Breadcrumbs";
import MetaHelmet from "../../SEO/MetaHelmet";
// Content
import { Headings } from "../../jsComponents/ContentData";
import SeoContent from "../../SEO/SeoContent";
const topicsContent = [
  {
    title: "C-Corp",
    description:
      "Some certain benefits and drawbacks come with electing to be taxed as a C-Corporation as the entity is treated as a separate legal person. Due to it having its respective tax laws, the intricacies behind these laws can make maneuvering through these laws particularly difficult. Our team has the expertise to assist business owners in evaluating the most tax-beneficial business solutions.",
  },
  {
    title: "S-Corp",
    description:
      "Though S-corporations are related to the C-Corporation, they are treated significantly differently under the tax code. As a pass-through entity, S-Corps are subject to the same tax rate as each shareholder. S-Corps can also provide relief from the self-employment tax that is imposed on partnerships and sole proprietorships. Our team is ready to assist you in the preparation of your S-Corp tax return and help you understand how it will affect your personal tax return.",
  },
  {
    title: "Partnership",
    description:
      "Partnerships are largely considered one of the most complex entity structures simply due to the ease with which partners can structure the partnership. Unlike S-Corps, partnerships are limited in how they can be formed. They are much more flexible in how they can be set up. Partnerships are also subject to self-employment tax and, though partners are not required to take wages like S-Corp shareholders, taxable guaranteed payments are a requirement in certain situations. Navigating the ultimate tax effects of this type of business is something we are ready to help you with.",
  },
  {
    title: "Not For Profit Organizations",
    description:
      "Every Not-For-Profit organization has a mission and the Eton M. Reneau & Company team wants to help your organization meet that mission. Though Not-For-Profit Organizations are not generally subject to income tax, our team is here to assist you in compliance with the IRS. We also possess the expertise to guide you through those sticky situations that do not involve taxable events for your Not-For-Profit.",
  },
];

const ContentDiv = (props) => {
  const content = props.content;
  return (
    <div>
      <h3>{content.title}</h3>
      <hr className="mt-1" />
      <p className="mt-1" align="justify" style={{ lineHeight: 2 }}>
        {content.description}
      </p>
    </div>
  );
};
const Consulting = () => {
  return (
    <section id="personal-tax-service">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Business Tax</h2>
        </div>
        <div className="row gy-5">
          <p align="justify" style={{ lineHeight: 2 }}>
            An important part of any business is determining how taxes will have
            an impact on the various business decisions a company makes. With
            tax law having so many intricacies, it is hard for many business
            owners and decision-makers to keep up with all of them. That is,
            however, how the team at Elton M. Reneau & Company can assist. With
            a team of fully experienced and knowledgeable tax experts, we are
            ready to provide your business with all the necessary expertise to
            make any business decision.
          </p>

          {topicsContent.map((topic) => (
            <ContentDiv content={topic} key={Math.random()} />
          ))}
        </div>
      </div>
    </section>
  );
};
const ServicesTaxBusiness = () => {
  Headings.services.pageNav = "Business Tax";
  const [content] = SeoContent.pages.filter(
    (page) => page.page === "/services-business-tax"
  );
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.services} />
      <Consulting />
    </>
  );
};

export default ServicesTaxBusiness;
