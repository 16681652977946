import React from "react";

//Components
import Hero from "../Sections/Hero";
import WhyUs from "../Sections/WhyUs";
import ServicesList from "../Sections/ServicesList";
import CallToAction from "../Sections/CallToAction";

// import SubscribeEmail from "../Sections/SubscribeEmail";
import SeoContent from "../SEO/SeoContent";
import MetaHelmet from "../SEO/MetaHelmet";
import Certifications from "../Sections/Certifications";

const Home = () => {
  const [content] = SeoContent.pages.filter((page) => page.page === "/");
  return (
    <>
      <MetaHelmet seo={content} />            
      <Hero />
      <WhyUs />
      <ServicesList />
      <CallToAction />
      <Certifications />
      {/* <SubscribeEmail /> */}
    </>
  );
};

export default Home;
