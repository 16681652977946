import { Typography, Grid } from "@mui/material";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";


import { News } from "../jsComponents/ContentData";
// News=[];

const css = {
    marquee: {
        backgroundColor: '#1b2f45', height: '8rem'
    },
    grid1: { justifyContent: "center", alignItems: "start" },
    postGrid: { maxWidth: { md: "25rem", xs: '21rem' }, borderLeft: "2px solid lightblue", paddingLeft: '0.5rem', paddingTop: '0.5rem' },
    postTitle: { fontSize: '1.1rem', color: "white" },
    postDescription: { fontSize: '0.7rem', color: "white" }
}

const Post = (props) => {
    const { title, description } = props.data;
    const link = `/news/?post=${title}`;
    return <>
        <Grid item sx={css.postGrid}>
            <Typography variant="h1" sx={css.postTitle}><Link to={link} target="_self">{title}</Link></Typography>
            <Typography variant="text" sx={css.postDescription}>{description} &nbsp; <Link to={link} target="_self">Know More</Link></Typography>
        </Grid>
    </>
}

const ScrollingNews = () => {
    let newsPosts = News.slice(1);
    return <>
        <Grid variant="container">
            <Marquee pauseOnHover={true} style={css.marquee}>
                <Grid container direction='row' gap={3} sx={css.grid1}>
                    {newsPosts.map((post, index) => <Post data={post} key={index} />)}
                </Grid>
            </Marquee>
        </Grid>
    </>
}

export default ScrollingNews;