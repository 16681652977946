// Components
import Breadcrumbs from "../../Breadcrumbs";
import MetaHelmet from "../../SEO/MetaHelmet";

// Content
import { Headings } from "../../jsComponents/ContentData";
import SeoContent from "../../SEO/SeoContent";

const CFOParas = [
  "In today's business environment, companies recognize the value of outsourcing critical functions. Outsourced CFO services offer businesses the opportunity to tap into the expertise of skilled financial professionals without the need for a full-time CFO. With a deep understanding of financial analysis, risk management, and strategic planning, outsourced CFOs bring immense value to organizations.",
  "Outsourcing CFO services is cost-effective for small and medium-sized businesses. By opting for outsourced CFO services, businesses can access top-tier financial expertise at a fraction of the cost. Additionally, outsourced CFOs work flexibly, allowing businesses to scale their financial resources as needed. Whether it's financial reporting, budgeting, or financial strategy, outsourced CFOs provide tailored solutions.",
];
const cfoServicesList = [
  "Strategic financial guidance and decision-making support",
  "Cost-effective alternative to hiring a full-time CFO",
  "Flexible scalability of financial resources based on business needs",
  "Expertise in financial reporting, budgeting, and cash flow management",
  "Access to a broad skill set and industry knowledge",
  "Valuable insights and best practices from diverse client experiences",
  "Identification of growth opportunities and optimization of financial processes",
  "Implementation of effective risk management strategies",
  "Stay up-to-date with industry trends and regulatory changes",
  "Ensure compliance and adaptability in a dynamic business environment.",
];
const Content = () => {
  return (
    <section id="personal-tax-service">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Outsourced CFO Services</h2>
        </div>
        <div className="row gy-1">
          {CFOParas.map((para) => {
            return (
              <p align="justify" style={{ lineHeight: 2 }} key={Math.random()}>
                &nbsp;&nbsp;{para}
              </p>
            );
          })}
          <p
            align="justify"
            style={{ lineHeight: 2, fontWeight: "bold", fontSize: "1.3rem" }}
          >
            Unlocking the Benefits of Our Part-Time CFO Service...
          </p>
          <br />
          <ul className="mt-2 ps-5" style={{ lineHeight: 2 }}>
            {cfoServicesList.map((li) => {
              return <li key={Math.random()}>{li}</li>;
            })}
            and more...
          </ul>
        </div>
      </div>
    </section>
  );
};
const ServicesCFO = () => {
  Headings.services.pageNav = "Outsourced CFO Services";
  const [content] = SeoContent.pages.filter(
    (page) => page.page === "/services-cfo"
  );
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.services} />;
      <Content />
    </>
  );
};
export default ServicesCFO;
