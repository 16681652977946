import React from "react";
import { Link } from "react-router-dom";

// Team Profiles Data
import { TeamData } from "../jsComponents/ContentData";

const TeamMembers = (props) => {
  return (
    <section id="team" className="team">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Our Team</h2>
        </div>
        <div className="row gy-4">
          {/* Iterating Teams Member from Team Data  */}
          {TeamData.map((member) => {
            return (
              <div
                key={member.id}
                className="col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                {/* Sending Member Data using LINK STATE  to open Team Profile Page with Selected MEMBER Profile*/}
                <Link to="/teamProfile" state={member}>
                  <div className="team-member">
                    <div className="member-img">
                      <img src={member.image} className="img-fluid" alt="" />
                    </div>
                    <div className="member-info">
                      <h4>{member.name}</h4>
                      <span>{member.designation}</span>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TeamMembers;
