import React from "react";
import "./taxCenter.css";
// Components
import Breadcrumbs from "../../Breadcrumbs";
import TaxCenterCard from "../../Sections/taxCenterCard";
import MetaHelmet from "../../SEO/MetaHelmet";

// Content
import { Headings, PagesData } from "../../jsComponents/ContentData";
import SeoContent from "../../SEO/SeoContent";

const Content = () => {
  const TaxCenterCardsContent = PagesData.taxCenterCards;
  const [content] = SeoContent.pages.filter(
    (page) => page.page === "/tax-center"
  );
  return (
    <>
      <MetaHelmet seo={content} />
      <section id="services-list" className="services-list">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4 mt-1 d-flex justify-content-center">
            {/* card iterating  */}
            {TaxCenterCardsContent.map((card) => {
              return <TaxCenterCard key={card.key} card={card} />;
            })}
          </div>
        </div>
      </section>
    </>
  );
};
const TaxCenter = () => {
  Headings.taxCenter.pageNav = "Tax Center";
  return (
    <>
      <Breadcrumbs content={Headings.taxCenter} />
      <Content />
    </>
  );
};

export default TaxCenter;
