import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DialogContentText, Grid, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const ShowConfirm = (props) => {
  const Styles = {
    accordion: {
      color: "#344866",
      backgroundColor: "#cfe1fc",
    },
    modalDescription: {
      color: "#344866",
      fontWeight: "bold",
      paddingBottom: "0.5rem",
    },
    content: { color: "#344866", lineHeight: "normal", fontSize: "0.9rem" },
    ulcontent: {
      paddingLeft: "2.3rem",
      marginTop: "0",
      paddingTop: "0",
      margin: "0",
      textAlign: "justify",
    },
    title: {
      color: "#344866",
      lineHeight: "normal",
      fontSize: "1rem",
      fontWeight: "bold",
    },
    oltitle: {
      paddingLeft: "1.5rem",
      marginLeft: "1.2rem",
      marginBottom: "3px",
      padding: "0",
      marginTop: "1em",
    },
  };
  const CLIStatus = props.CLIStatus;
  return (
    <>
      <Dialog
        fullScreen={props.fullScreen}
        open={props.open}
        onClose={props.handleClose}
        scroll={props.scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {/* Dialog content */}
        <DialogTitle
          id="scroll-dialog-title"
          style={{ color: "#343a40", fontWeight: "bold" }}
        >
          Attention
        </DialogTitle>
        <DialogContent dividers={props.scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={props.descriptionElementRef}
            tabIndex={-1}
          >
            <Accordion
              sx={{ display: CLIStatus ? "none" : "block" }}
              style={Styles.accordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography style={{ fontWeight: "Bold" }}>
                  Instructions to Use File Drop
                </Typography>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <Grid container>
                  <Grid item>
                    {/* <!-- Step 1: Accessing File Drop --> */}
                    <Typography style={Styles.title}>
                      <ol
                        start={"1"}
                        style={{
                          ...Styles.oltitle,
                          marginTop: 0,
                        }}
                      >
                        <li>Accessing File Drop</li>
                      </ol>
                    </Typography>
                    <Typography style={Styles.content}>
                      <ul style={Styles.ulcontent}>
                        <li>
                          Click on the <strong>'OPEN'</strong> button to open
                          the File Drop.
                        </li>
                      </ul>
                    </Typography>

                    {/* <!-- Step 2: Entering Details --> */}
                    <Typography style={Styles.title}>
                      <ol start={"2"} style={Styles.oltitle}>
                        <li>Entering Details</li>
                      </ol>
                    </Typography>
                    <Typography style={Styles.content}>
                      <ul style={Styles.ulcontent}>
                        <li>
                          In the Information Form, enter your{" "}
                          <strong>
                            Email, First Name, Last Name, and Company
                          </strong>
                          . (This information is required to identify the sender
                          of the files.)
                        </li>
                      </ul>
                    </Typography>

                    {/* <!-- Step 3: Selecting the Recipient --> */}
                    <Typography style={Styles.title}>
                      <ol start="3" style={Styles.oltitle}>
                        <li>Selecting the Recipient</li>
                      </ol>
                    </Typography>
                    <Typography style={Styles.content}>
                      <ul style={Styles.ulcontent}>
                        <li>
                          Once you access File Drop, select a{" "}
                          <strong>Recipient</strong> for your files. Choose the
                          appropriate contact from the dropdown list.
                        </li>
                      </ul>
                    </Typography>

                    {/* <!-- Step 4: Uploading Files --> */}
                    <Typography style={Styles.title}>
                      <ol start="4" style={Styles.oltitle}>
                        <li>Uploading Files</li>
                      </ol>
                    </Typography>
                    <Typography style={Styles.content}>
                      <ul style={Styles.ulcontent}>
                        <li>
                          Files can be uploaded by either{" "}
                          <strong>Dragging</strong> them into the File Drop area
                          or by using the <strong>'Browse files'</strong> option
                          to select files from your computer. You can upload one
                          or more files at a time.
                        </li>
                      </ul>
                    </Typography>

                    {/* <!-- Step 5: Sending the Files --> */}
                    <Typography style={Styles.title}>
                      <ol start="5" style={Styles.oltitle}>
                        <li>Sending the Files</li>
                      </ol>
                    </Typography>
                    <Typography style={Styles.content}>
                      <ul style={Styles.ulcontent}>
                        <li>
                          Once the files have been added, click on the{" "}
                          <strong>'Upload'</strong> or <strong>'Send'</strong>{" "}
                          button. This will transfer the files to the designated
                          Recipient ShareFile account.
                        </li>
                      </ul>
                    </Typography>

                    {/* <!-- Step 6: Confirmation --> */}
                    <Typography style={Styles.title}>
                      <ol start="6" style={Styles.oltitle}>
                        <li>Confirmation</li>
                      </ol>
                    </Typography>
                    <Typography
                      style={Styles.content}
                      sx={{ paddingBottom: "1rem" }}
                    >
                      <ul style={Styles.ulcontent}>
                        <li>
                          After a successful upload, a confirmation message or
                          an <strong>'Uploaded' </strong>
                          label will appear. If you need to send more files,
                          repeat steps 4 and 5.
                        </li>
                        <li>
                          Please ensure to <strong>close</strong> the File Drop
                          window after completing the upload process.
                        </li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Typography style={{ paddingTop: "1rem", fontStyle: "italic" }}>
              <strong>Note: </strong> {props.content}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="medium" onClick={props.handleOpen}>
            OPEN
          </Button>
          <Button onClick={props.handleClose}>CLOSE</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShowConfirm;
