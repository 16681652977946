import React, { useEffect } from "react";
import { ImageContent } from "../jsComponents/ContentData";
import Swiper, { Navigation, Pagination, EffectCoverflow } from "swiper";
Swiper.use([Navigation, Pagination, EffectCoverflow]);

const WhyUs = () => {
  useEffect(() => {
    new Swiper(".slides-1", {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "none",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    new Swiper(".slides-3", {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        1200: {
          slidesPerView: 3,
        },
      },
    });
  }, []);

  return (
    <section id="why-us" className="why-us">
      <div className="container" data-aos="fade-up">
        <div className="section-header mt-5">
          <h2>Why Choose Us</h2>
        </div>
        <div className="row g-0" data-aos="fade-up" data-aos-delay="200">
          <div
            className="col-xl-5 img-bg"
            style={{
              backgroundImage: `url(${ImageContent.whyus_bgImage})`,
              backgroundPosition: "center",
            }}
          ></div>

          <div className="col-xl-7 slides  position-relative">
            <div className="slides-1 swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="item">
                    <h3 className="mb-3">PERSONAL TAX</h3>
                    <p style={{ textAlign: "justify" }}>
                      Our team has the knowledge and skills needed to help you
                      better manage the financial health of your business and
                      simplify your Bookkeeping. You'll receive monthly and
                      quarterly reports on your Accounting, Bookkeeping, and
                      taxes and know exactly where you stand financially
                      {/* on a recurring basis */} regularly.
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="item">
                    <h3 className="mb-3">BUSINESS TAX</h3>
                    <p style={{ textAlign: "justify" }}>
                      With a team of {/* full  */} fully experienced and
                      knowledgeable tax experts, we are ready to provide your
                      business with all the necessary expertise to make any
                      business decision.
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="item">
                    <h3 className="mb-3">BOOKKEEPING</h3>
                    <p style={{ textAlign: "justify" }}>
                      At Elton M. Reneau & Company, our Bookkeeping services
                      make it possible for you to spend your time where it is
                      best spent - on your areas of expertise and interest.
                    </p>
                  </div>
                </div>
              </div>
              <div className="swiper-pagination"></div>
            </div>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default WhyUs;
