import React from "react";

// Components
import AboutContent from "../Sections/AboutContent";
import WhyUs from "../Sections/WhyUs";
import CallToAction from "../Sections/CallToAction";
import TeamMembers from "../Sections/TeamMembers";
import Breadcrumbs from "../Breadcrumbs";
import MetaHelmet from "../SEO/MetaHelmet";

// Content
import { Headings, PagesData } from "../jsComponents/ContentData";
import SeoContent from "../SEO/SeoContent";

const About = () => {
  const [content] = SeoContent.pages.filter((page) => page.page === "/about");
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.about} />
      <AboutContent pageContent={PagesData.about} />
      <WhyUs />
      <CallToAction />
      <TeamMembers />
    </>
  );
};

export default About;
