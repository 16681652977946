import React from "react";
// Components
import Breadcrumbs from "../../Breadcrumbs";
import MetaHelmet from "../../SEO/MetaHelmet";
// Content
import { Headings } from "../../jsComponents/ContentData";
import SeoContent from "../../SEO/SeoContent";

const topics = [
  {
    title: "Business Consulting ",
    description:
      "We gauge our worth by the personal and business successes of our clients. By helping you identify areas that negatively affect profitability and growth, we can develop practical solutions. In addition to acting as a sounding board for management, we provide comprehensive, flexible strategies that address issues related to your business.",
  },
  {
    title: "Quickbooks Consulting",
    description:
      "Our consulting team offers a variety of services to implement, train, operate, and maintain the accounting records of small businesses. The team is also prepared to assist in the transition to more industry-specific software.",
  },
];
const ContentComponent = (props) => {
  const content = props.content;
  return (
    <>
      <h3>{content.title}</h3>
      <hr className="mt-1" />
      <p className="mt-1" align="justify" style={{ lineHeight: 2 }}>
        {content.description}
      </p>
    </>
  );
};
const Consulting = () => {
  return (
    <section id="personal-tax-service">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Consulting Services</h2>
        </div>
        <div className="row gy-5">
          {topics.map((topic) => (
            <ContentComponent content={topic} key={Math.random()} />
          ))}
        </div>
      </div>
    </section>
  );
};
const ServicesConsulting = () => {
  Headings.services.pageNav = "Consulting Services";
  const [content] = SeoContent.pages.filter(
    (page) => page.page === "/services-consulting"
  );
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.services} />
      <Consulting />
    </>
  );
};

export default ServicesConsulting;
