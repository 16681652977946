import React from "react";
import { NavLink } from "react-router-dom";
import { PagesData } from "./jsComponents/ContentData";
// import { simpleConfirm } from "react-simple-dialogs";
import SocialMediaIcons from "./Sections/SocialMediaIcons";

//Sohw Confirm Imports
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ShowConfirm from "./Sections/ShowConfirm";
import { useState } from "react";
// import axios from "axios";

// const showConfirmation = async () => {
//   if (
//     await simpleConfirm({
//       message:
//         "Opening File Drop Service, Secured by ShareFile for reliable and safe file exchange",
//       confirmLabel: "Open",
//       cancelLabel: "Close",
//     })
//   ) {
//     window.open(
//       "https://eltonreneau.sharefile.com/filedrop/dx7df445-4896-46d0-a69b-f4553c2e47c2",
//       "_blank"
//     );
//   } else {
//   }
// };

const Footer = () => {
  //Show Confirms Hooks & Functions
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [CLIStatus, setCLIStatus] = React.useState(false);
  const onClickHandle = (popupType) => {
    if (popupType === "ShareFile") {
      setCLIStatus(true);
    } else if (popupType === "FileDrop") {
      setCLIStatus(false);
    }

    setOpen(true);
    setScroll();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    window.open(
      "https://eltonreneau.sharefile.com/share/filedrop/dx7df445-4896-46d0-a69b-f4553c2e47c2",
      "_blank"
    );
    setOpen(false);
  };
  const handleOpenShareFile = () => {
    window.open("https://eltonreneau.sharefile.com", "_blank");
    setOpen(false);
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const contact = PagesData.contact;
  return (
    <>
      <footer id="footer" className="footer">
        <div className="footer-content">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-5 col-md-12 footer-info">
                <div>
                  <NavLink to="/" className="logo d-flex align-items-center">
                    <span>Elton M. Reneau & Co., PC</span>
                  </NavLink>
                  <p style={{ textAlign: "justify" }}>
                    &ensp; We respect your privacy 100%, so the information that
                    you provide will remain strictly confidential. Nevertheless,
                    a copy of your message might be stored in our records as a
                    secure database entry for archival purposes.
                  </p>
                </div>
                <div>
                  <SocialMediaIcons
                    navbarMobile={false}
                    head={false}
                    foot={true}
                    smfoot={false}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bi bi-dash"></i>{" "}
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>{" "}
                    <NavLink to="/about">About us</NavLink>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>{" "}
                    <NavLink to="/services">Services</NavLink>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>{" "}
                    <NavLink
                      // to="javascript: void(0)"
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        // showConfirmation();
                        onClickHandle("FileDrop");
                      }}
                    >
                      File Drop
                    </NavLink>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>{" "}
                    <NavLink
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        onClickHandle("ShareFile");
                      }}
                    >
                      Client Login
                    </NavLink>
                  </li>
                  {/* <li>
                  <i className="bi bi-dash"></i>{" "}
                  <NavLink
                    to="https://www.clover.com/pos-systems/accept-payments"
                    target="_blank"
                    rel="noopener  noreferrer"
                  >
                    Payment Portal
                  </NavLink>
                </li> */}
                </ul>
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bi bi-dash"></i>
                    <NavLink to="/services-tax">Tax </NavLink>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>
                    <NavLink to="/services-accounting">Accounting</NavLink>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>
                    <NavLink to="/services-consulting">Consulting</NavLink>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>
                    <NavLink to="/services-cfo">Outsourced CFO </NavLink>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>
                    <NavLink to="/services-rei">Real Estate Investing </NavLink>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>
                    <NavLink to="/services-nptax">Non-Profit Tax </NavLink>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>
                    <NavLink to="/services-boi">Beneficial Owner Information (BOI) </NavLink>
                  </li>
                </ul>
              </div>
              <div className="py-2 col-lg-3 col-md-12 footer-contact text-center text-md-start">
                <h4>Contact Us</h4>
                <div className="text-center text-md-start">
                  {contact.company}
                  <br />
                  <NavLink
                    to="https://goo.gl/maps/zP63rBHyV63cv1no9"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {contact.address}
                  </NavLink>{" "}
                  <br />
                  {contact.statePin} <br />
                  {contact.country} <br />
                  <button
                    onClick={() => {
                      window.open(contact.googleMapReview, "_blank");
                    }}
                    className="btn btn-md my-2"
                    style={{ backgroundColor: "#31547c", color: "white" }}
                  >
                    Review Us On Google
                  </button>
                  <br />
                  <strong>Phone: </strong>
                  <NavLink to={`tel:+1${contact.phone}`}>{contact.phone}</NavLink>
                  <br />
                  <strong>Fax: </strong>
                  <span>{contact.fax}</span>
                  <br />
                  <strong>Email: </strong>
                  <NavLink to={`mailto:${contact.email}?subject=Mail%20from%20reneaucpa.com`}>{contact.email}</NavLink>
                  <br></br>
                  <SocialMediaIcons head={false} foot={false} smfoot={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-legal">
          <div className="container">
            <div className="copyright">
              {/* <span style={serverAlive}>&copy;</span> Copyright{" "} */}
              &copy; Copyright{" "}
              <strong>
                <span>{PagesData.contact.company}</span>
              </strong>{" "}
              All Rights Reserved
            </div>
            {/* <div className="credits">Designed by SiteMaker</div> */}
          </div>
        </div>
      </footer>
      <ShowConfirm
        content={
          CLIStatus ? (
            <span>
              By clicking <strong>OPEN</strong> Button will Securely Redirecting
              to the Elton's <strong>ShareFile Client Portal</strong>.
            </span>
          ) : (
            <span>
              if you are new to <strong>File Drop</strong>, please read the
              Instructions before proceeding.
            </span>
          )
        }
        CLIStatus={CLIStatus}
        open={open}
        fullScreen={fullScreen}
        handleClose={handleClose}
        handleOpen={CLIStatus ? handleOpenShareFile : handleOpen}
        scroll={scroll}
        descriptionElement={descriptionElementRef}
      />
    </>
  );
};

export default Footer;
