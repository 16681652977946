import React from "react";
// import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import Main from "../Main";
import ScrollTop from "./ScrollTop";
import { SimpleDialogContainer } from "react-simple-dialogs";

// const removeReactHelmetAttributes = () => {
//   document.querySelectorAll("[data-react-helmet]").forEach((element) => {
//     element.removeAttribute("data-react-helmet");
//   });
// };

const Wrapper = (props) => {
  // useEffect(() => {
  //   removeReactHelmetAttributes();
  // }, []);
  return (
    <>
      {/* confirm dialog componant */}
      <SimpleDialogContainer />
      <ScrollTop />
      <Header />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </>
  );
};
export default Wrapper;
