import Breadcrumbs from "../Breadcrumbs";
import { Headings } from "../jsComponents/ContentData";

const Content = () => {
  return (
    <section id="personal-tax-service">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          {/* <h2>404 Page Not Found</h2> */}
          <h1>404 - Page Not Found</h1>
          <br />
          <h4>Sorry, the requested page does not exist.</h4>
        </div>
      </div>
    </section>
  );
};


export default function Error404() {
  Headings.contact.pageName = "Error";
  Headings.contact.pageNav = "Page Not Found";
  console.error("Page Not Found");
  return (
    <>
      <Breadcrumbs content={Headings.contact} />;
      <Content />
    </>
  );
}
