import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink, useSearchParams } from "react-router-dom";
import { NotificationManager, NotificationContainer, } from "react-notifications";
import "react-notifications/lib/notifications.css";

//Components
import CallToAction from "../Sections/CallToAction";
import Breadcrumbs from "../Breadcrumbs";
import MetaHelmet from "../SEO/MetaHelmet";

// Content
import { Headings, PagesData } from "../jsComponents/ContentData";
import SeoContent from "../SEO/SeoContent";

const categoryOptions = [
  "Select Category",
  "Tax Services",
  "Accounting Services",
  "Consulting Services",
  "Outsourced CFO Services",
  "Real Estate Investing Services",
  "Non-Profit Tax Services",
  "Beneficial Owner Information (BOI)",
  "Others",
];
const categorySlugOptions = categoryOptions.map((service, index) => index === 0 ? '' : service.toLowerCase().replace(/\s+/g, '-'));
const Input = (props) => {
  const options = props.options;
  return (
    <div
      className={`${options.name !== "subject" && "col-md-6"} form-group ${options.class
        }`}
    >
      <input
        value={options.value}
        onChange={options.onChange}
        type={options.type}
        name={options.name}
        className="form-control"
        placeholder={options.placeholder}
        maxLength={options.maxlength}
        required={true}
      />
    </div>
  );
};
const SelectInput = (props) => {
  return (
    <div className="form-group mt-3 mb-3">
      <select
        className="form-control mb-3"
        name="category"
        value={props.value}
        onChange={props.onChange}
        required
      >
        {props.options.map((option) => {
          return (
            <option
              key={Math.random()}
              value={option === "Select Category" ? "" : option}
            >
              {option}
            </option>
          );
        })}
      </select>
    </div>
  );
};

const ContactForm = () => {
  const [searchParams] = useSearchParams();
  const categoryFromURL = searchParams.get('subject');
  const convertedOption = (option) => {
    return option
      .split('-')                                  // Split by hyphen
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize each word
      .join(' ')                                   // Join with space
      .replace(/\s\((.*)\)/, (_, match) => ` (${match.toUpperCase()})`);
  }
  const categoryOptionIsValid = (categoryFromURL) => {
    const isInSlugs = categorySlugOptions.includes(categoryFromURL); //includes in slug 
    if (!isInSlugs) {
      return { isValid: isInSlugs, option: categoryFromURL };
    }
    const option = isInSlugs ? convertedOption(categoryFromURL) : false; //convert slug to option
    const isInOptions = categoryOptions.includes(option);
    return ({ isValid: isInOptions && isInSlugs, option })
  };
  const category = categoryOptionIsValid(categoryFromURL)

  const defaultFormData = {
    name: "",
    email: "",
    phone: "",
    location: "",
    subject: "",
    category: "",
    message: "",
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [formErrorMsg, setFormErrorMsg] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    const payload = formData;

    payload.timeStamp = new Date()
      .toISOString()
      .replace("T", " ")
      .substring(0, 19);

    if (payload.email.length > 5) {
      try {
        const host_url = `${process.env.REACT_APP_MAIL_SERVER}/contactus`;
        // console.log("host url: " + host_url + " Payload: ", payload);

        await axios.post(host_url, payload).then((response) => {
          // console.log(response.data);
          response.data.emailSent
            ? NotificationManager.success(
              "Thank you for Contact Us!  We Will Reach you Shortly..."
            )
            : <></>
          // console.log(response);
        });

        setIsSubmitted(false);
        setFormData(defaultFormData);
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          //backend server status
          // setFormErrorMsg("Backend Server Under Maintanance...");
          NotificationManager.error("Backend Server Under Maintanance...");
          setFormErrorMsg("");
          setIsSubmitted(false);
          setFormData(defaultFormData);
          // } else if (error.code === "ERR_BAD_REQUEST") {
          //   //prevent any issues
          //   NotificationManager.error("Internal Error");
          //   setFormErrorMsg("");
          //   setIsSubmitted(false);
        } else {
          NotificationManager.error(
            "Please Contact with Email",
            error.response.data.errorMsg
          );
          setFormErrorMsg("");
          setIsSubmitted(false);
          setFormData(defaultFormData);
          // console.log(error);
        }
      }
    } else {
      setFormErrorMsg("email");
      setIsSubmitted(false);
    }
  };

  const validateName = (inputName) => {
    //Name Validation Function
    const regex = /^[a-zA-Z ]+$/;
    return regex.test(inputName);
  };

  const setDataFunction = (inputData) => {
    let { name, value } = inputData;
    if (name === "phone") {
      //Phone Number Validation with US Number Format
      const digits = value.replace(/[^0-9]/g, "");
      if (digits.length === 10) {
        value = digits.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      } else {
        value = digits;
      }
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  useEffect(() => {
    if (category.isValid) {
      setFormData((prevState) => ({ ...prevState, category: category.option }));
    }
  }, [category])

  const handleInputChange = (e) => {
    if (e.target.name === "name") {
      //Name Validation
      if (validateName(e.target.value) || e.target.value === "") {
        setDataFunction(e.target);
      }
    } else if (e.target.name === "phone") {
      //Phone Validation
      setDataFunction(e.target);
    } else {
      setDataFunction(e.target);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <h2 className="mb-4" style={{ textAlign: "center" }}>
          HOW CAN WE HELP YOU?
        </h2>
        <div className="row">
          <Input
            required={true}
            options={{
              value: formData.name,
              onChange: handleInputChange,
              name: "name",
              class: "",
              type: "text",
              placeholder: "Name",
              maxlength: 35,
            }}
          />
          <Input
            options={{
              value: formData.email,
              onChange: handleInputChange,
              name: "email",
              class: "mt-3 mt-md-0",
              type: "email",
              placeholder: "Email",
              maxlength: "254",
            }}
          />
        </div>
        <div className="row mt-3">
          <Input
            options={{
              value: formData.phone,
              onChange: handleInputChange,
              name: "phone",
              class: "",
              type: "text",
              placeholder: "Phone Number",
              maxlength: 10,
            }}
          />
          <Input
            options={{
              value: formData.location,
              onChange: handleInputChange,
              name: "location",
              class: "mt-3 mt-md-0",
              type: "location",
              placeholder: "Location",
              maxlength: 50,
            }}
          />
        </div>
        <Input
          options={{
            value: formData.subject,
            onChange: handleInputChange,
            name: "subject",
            class: "mt-3",
            type: "text",
            placeholder: "Subject",
            maxlength: "100",
          }}
        />

        <SelectInput
          value={formData.category}
          onChange={handleInputChange}
          name="category"
          options={categoryOptions}
          required
        />
        <div className="form-group mt-3">
          <textarea
            value={formData.message}
            onChange={handleInputChange}
            className="form-control"
            name="message"
            rows="5"
            placeholder="Message"
            required
          ></textarea>
        </div>
        <div className="text-center mt-3">
          <button
            style={{ width: "8rem" }}
            className="btn btn-primary"
            type="submit"
            disabled={isSubmitted}
          >
            Submit
          </button>
        </div>
        <div className="form-group">
          <p
            onChange={handleInputChange}
            name="formErrorMsg"
            className="text-info mt-3"
            style={{ textAlign: "center" }}
          >
            {formErrorMsg === "email"
              ? "Please Enter Valid Email"
              : formErrorMsg}
          </p>
        </div>
      </form>
    </>
  );
};
const Content = () => {
  const contact = PagesData.contact;
  const timings = PagesData.contact.timings;
  Headings.contact.pageName = "Contact";
  Headings.contact.pageNav = "Contact";
  return (
    <section id="contact" className="contact">
      <div className="container position-relative" data-aos="fade-up">
        <div className="row gy-4 d-flex justify-content-end">
          <div className="col-lg-5" data-aos="fade-up" data-aos-delay="100">
            <div className="info-item d-flex">
              <i className="bi bi-geo-alt flex-shrink-0"></i>
              <div>
                <h4>Location:</h4>
                <NavLink
                  to="https://goo.gl/maps/zP63rBHyV63cv1no9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>{`${contact.address}, ${contact.statePin}`}</p> <br />
                </NavLink>
              </div>
            </div>
            <div className="info-item d-flex">
              <i className="bi bi-envelope flex-shrink-0"></i>
              <div>
                <h4>Email:</h4>
                <NavLink
                  to={`mailto:${contact.email}?subject=Mail from reneaucpa.com`}
                  rel="noreferrer"
                >
                  <p>{contact.email}</p>
                </NavLink>
              </div>
            </div>
            <div className="info-item d-flex">
              <i className="bi bi-phone flex-shrink-0"></i>
              <div>
                <h4>Call:</h4>
                <NavLink to="tel:+1-830-632-5226" rel="noreferrer">
                  <p>{contact.phone}</p>
                </NavLink>
              </div>
            </div>
            <div className="info-item d-flex">
              <i className="bi bi-clock flex-shrink-0"></i>
              <div>
                <h4>Office Hours:</h4>
                <p>
                  {`${timings.fromDay} - ${timings.toDay} `}
                  <br />
                  {`${timings.fromTime} - ${timings.toTime}`}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="250">
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
};
const Contact = () => {
  const [content] = SeoContent.pages.filter((page) => page.page === "/contact");
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.contact} />
      <Content />
      <CallToAction />
      <NotificationContainer />
    </>
  );
};

export default Contact;
