// Images
const ImageContent = {
  // about_header_image: `${process.env.PUBLIC_URL}/assets/img/about-header.jpg`,
  // about_image: `${process.env.PUBLIC_URL}/assets/img/about.jpg`,
  // whyus_bgImage: `${process.env.PUBLIC_URL}/assets/img/why-us-bg.jpg`,
  // teamProfileHeader_image: `${process.env.PUBLIC_URL}/assets/img/team-header.jpg`,
  // servicesHeader_image: `${process.env.PUBLIC_URL}/assets/img/services-header.jpg`,
  // taxCenterHeader_image: `${process.env.PUBLIC_URL}/assets/img/taxcenter-header.jpg`,
  // usefullLinksHeader_image: `${process.env.PUBLIC_URL}/assets/img/usefullLinks.jpg`,
  // formsInstructionsHeader_image: `${process.env.PUBLIC_URL}/assets/img/forms_instructions-header.jpg`,
  // careersHeader_image: `${process.env.PUBLIC_URL}/assets/img/careers-header.jpg`,
  // contactHeader_image: `${process.env.PUBLIC_URL}/assets/img/contact-header.jpg`,
  // Certificates: [
  //   {
  //     img: `${process.env.PUBLIC_URL}/assets/img/Certifications/QB-Online.webp`,
  //     alt: "INTUIT quickbooks Certified QuickBooks Online ProAdviser",
  //   },
  //   {
  //     img: `${process.env.PUBLIC_URL}/assets/img/Certifications/QBO_Desktop_Badge.webp`,
  //     alt: "INTUIT quickbooks Certified ProAdviser Desktop",
  //   },
  // ],
  about_header_image: `${process.env.PUBLIC_URL}/assets/img/pages/about-header.webp`,
  about_image: `${process.env.PUBLIC_URL}/assets/img/pages/about.webp`,
  whyus_bgImage: `${process.env.PUBLIC_URL}/assets/img/pages/why-us-bg.webp`,
  teamProfileHeader_image: `${process.env.PUBLIC_URL}/assets/img/pages/team-header.webp`,
  servicesHeader_image: `${process.env.PUBLIC_URL}/assets/img/pages/services-header.webp`,
  taxCenterHeader_image: `${process.env.PUBLIC_URL}/assets/img/pages/taxcenter-header.webp`,
  usefullLinksHeader_image: `${process.env.PUBLIC_URL}/assets/img/pages/usefullLinks.webp`,
  formsInstructionsHeader_image: `${process.env.PUBLIC_URL}/assets/img/pages/forms_instructions-header.webp`,
  careersHeader_image: `${process.env.PUBLIC_URL}/assets/img/pages/careers-header.webp`,
  contactHeader_image: `${process.env.PUBLIC_URL}/assets/img/pages/contact-header.webp`,
  Certificates: [
    {
      img: `${process.env.PUBLIC_URL}/assets/img/Certifications/QB-Online.webp`,
      alt: "INTUIT quickbooks Certified QuickBooks Online ProAdviser",
    },
    {
      img: `${process.env.PUBLIC_URL}/assets/img/Certifications/QBO_Desktop_Badge.webp`,
      alt: "INTUIT quickbooks Certified ProAdviser Desktop",
    },
  ],
};
// Headings
const Headings = {
  about: {
    image: ImageContent.about_header_image,
    pageName: "About",
    pageNav: "About",
  },
  services: {
    image: ImageContent.servicesHeader_image,
    pageName: "Services",
    pageNav: "",
  },
  team: {
    image: ImageContent.teamProfileHeader_image,
    pageName: "Team",
    pageNav: "Team",
  },
  contact: {
    image: ImageContent.contactHeader_image,
    pageName: "Contact ",
    pageNav: "Contact",
  },
  teamProfile: {
    image: ImageContent.teamProfileHeader_image,
    pageName: "Team Profile",
    pageNav: "",
  },
  careers: {
    image: ImageContent.careersHeader_image,
    pageName: "Careers",
    pageNav: "Careers",
  },
  taxCenter: {
    image: ImageContent.taxCenterHeader_image,
    pageName: "Tax Center",
    pageNav: "",
  },
  usefulLinks: {
    image: ImageContent.usefullLinksHeader_image,
    pageName: "Useful Links",
    pageNav: "Useful Links",
  },
  formsInstructions: {
    image: ImageContent.formsInstructionsHeader_image,
    pageName: "Forms & Instructions",
    pageNav: "Forms & Instructions",
  },
  fileDrop: {
    image: ImageContent.formsInstructionsHeader_image,
    pageName: "File Drop",
    pageNav: "File Drop",
  },
};
// Team Data
const teamImagesPath = "../../assets/img/team/";
const TeamData = [
  {
    id: 1,
    image: teamImagesPath + "team-1.jpg",
    name: "Elton M. Reneau",
    designation: "Founder",
    description: [
      {
        id: "d1",
        paragraph:
          "After graduating from Texas State University with a BBA in accounting Elton Reneau started his first position as a staff accountant for Motorola. He has held several positions in different areas of accounting, including controller, and has worked in multiple industries including manufacturing and insurance.",
      },
      {
        id: "d2",
        paragraph:
          "After 25 years of corporate accounting, he joined a public accounting firm. He has a long history as a tax accountant and has held his CPA license for over 20 years. He has worked in several CPA companies gaining extensive experience in both business and individual taxes. He especially enjoys the challenge of assisting businesses with their tax planning needs and being part of their success and growth.",
      },
      {
        id: "d3",
        paragraph:
          "Elton is very active with community organizations. His civic-minded activities include being Chairman of the United Way and the Downtown Rotary treasurer for many years. He is a Wurstfest Senior Opa, an Elder at the New Braunfels Presbyterian Church, and belongs to the Braunfels Foundation Trust, the Heritage Society of New Braunfels, and the Sophienburg Museum and Archives.",
      },
    ],
  },
];
// Pages Content
const PagesData = {
  // About Page Content
  about: {
    title: "Making Your Life Less Taxing",
    description:
      "As CPAs, We have the expertise that is needed to help you file your returns while also helping you to minimize your tax liability with careful planning.",
    paragraphs: [
      {
        key: 1,
        text: "Our team has the knowledge and skills needed to help you better manage the financial health of your business and simplify your bookkeeping.",
      },
      {
        key: 2,
        text: "Our team works with you to understand both you and your business needs so we may devise a plan, both for bookkeeping throughout the year and during tax season, to cultivate the growth of your business.",
      },
      {
        key: 3,
        text: "Our tax experts provide projections and specific tax planning advice to help you maximize your tax refund by taking advantage of all tax deductions eligible to you.",
      },
    ],
    image: ImageContent.about_image,
  },
  // Contact Page Content
  contact: {
    company: "Elton M. Reneau & Co., PC",
    address: "352 Landa Street, New Braunfels",
    googleMap: "https://goo.gl/maps/zP63rBHyV63cv1no9",
    googleMapReview: "https://g.page/elton-m--reneau-cpa/review?gm",
    statePin: "TX 78130",
    country: "United States",
    phone: " (830) 632-5226",
    fax: "(830) 632-5296",
    email: "info@reneaucpa.com",
    timings: {
      fromDay: "Monday",
      toDay: "Friday",
      fromTime: "9 AM",
      toTime: "5 PM",
    },
  },
  // Services Page Content
  services: [
    {
      serviceId: 1,
      icon: { iconStyle: "bi-briefcase", color: "#f57813" },
      title: "Tax Services",
      nav: "services-tax",
      description:
        "Tax planning and preparation form a winning combination for our individual and business clients. Whether you are an individual or a multi-tiered partnership, our experienced staff can develop tax strategies that take advantage of new tax laws and legislation.",
    },
    {
      serviceId: 2,
      icon: { iconStyle: "bi-card-checklist", color: "#15a04a" },
      title: "Accounting Services",
      nav: "services-accounting",
      description:
        "Providing financial information to our clients in a timely and accurate manner is a commitment we feel cannot be compromised. Meaningful, well-organized financial records ensure that your business operations will run more efficiently daily.",
    },
    {
      serviceId: 3,
      icon: { iconStyle: "bi-bar-chart", color: "#d90769" },
      title: "Consulting Services",
      nav: "services-consulting",
      description:
        "An important part of any business is determining how taxes will have an impact on the various business decisions a company makes. With tax law having so many intricacies, it is hard for many business owners and decision-makers to keep up with all of them. That is, however, how the team at Elton M. Reneau & Company can assist.",
    },
    {
      serviceId: 4,
      icon: { iconStyle: "bi-person-workspace", color: "#5a9f00" },
      title: "Outsourced CFO Services",
      nav: "services-cfo",
      description:
        "Looking to bring your business to the next level, but aren’t quite at the point where you can hire a full-time CFO in-house? We can help.",
    },
    {
      serviceId: 5,
      icon: { iconStyle: "bi-buildings", color: "#0771df" },
      title: "Real Estate Investing Services",
      nav: "services-rei",
      description:
        "Looking to invest in Real Estate? The Partners at Elton M. Reneau &Co., PC CPAs not only have experience helping clients but personal experience in raising money, tax structuring, bookkeeping, and tax reporting.",
    },
    {
      serviceId: 6,
      icon: { iconStyle: "bi-clipboard2-data", color: "#5a9f00" },
      title: "Non-Profit Tax Services",
      nav: "services-nptax",
      description:
        "We can help you set up and maintain your non-profit organization’s tax-exempt status by handling all the IRS reporting for you.",
    },
    {
      serviceId: 7,
      icon: { iconStyle: "bi-info-circle", color: "#9f6a00" },
      title: "Beneficial Ownership Information",
      nav: "services-boi",
      description:
        "Now offering Beneficial Owner Information (BOI) services to help businesses identify and verify individuals who hold significant control or ownership in a company. Ensure compliance with regulatory standards.",
    },
  ],
  // Tax Center Page Content
  taxCenterCards: [
    {
      key: "Card1",
      title: "Tax Rates",
      link: "/tax-rates",
      load: "_self",
      paragraph:
        "Explore the tax rates page to find information on  federal income tax rates, state and local taxes, and how they  are affected by income level and filing status.",
    },
    {
      key: "Card2",
      title: "IRS Publications",
      link: "https://www.irs.gov/publications",
      load: "_blank",
      paragraph:
        "IRS publications provide important information and guidance on tax regulations and requirements. These publications cover a wide range of topics, including tax forms, deductions, and credits.",
    },
    {
      key: "Card3",
      title: "Tax Calendar",
      link: "https://www.tax.gov/calendar/",
      load: "_blank",
      paragraph:
        "The IRS tax calendar provides important dates and deadlines for filing taxes and making payments. Simply select the tax year and browse the calendar to ensure timely compliance with tax laws.",
    },
    {
      key: "Card4",
      title: "Tax Refund Status",
      link: "https://sa.www4.irs.gov/irfof/lang/en/irfofgetstatus.jsp",
      load: "_blank",
      paragraph:
        "Tracking your fund is important when it comes to tax claims. Keeping detailed records of your income and expenses can help you maximize your deductions and credits, resulting in a lower tax bill.",
    },
  ],
  // Useful Links Page Content
  usefulLinks: [
    {
      key: 1,
      value: "Social Security Administration",
      link: "https://www.ssa.gov/",
    },
    {
      key: 2,
      value: "US Small Business Administration",
      link: "https://www.sba.gov/",
    },
    {
      key: 3,
      value: "US Department of Commerce",
      link: "https://www.commerce.gov/",
    },
    {
      key: 4,
      value: "Tax Information Sites IRS",
      link: "https://www.irs.gov/",
    },
    {
      key: 5,
      value: "Apply for an EIN (Employer Identification Number) Online",
      link: "https://www.irs.gov/businesses/small-businesses-self-employed/apply-for-an-employer-identification-number-ein-online",
    },
    {
      key: 6,
      value: "Texas Workforce Commission",
      link: "https://www.twc.texas.gov/",
    },
  ],
  //  Forms & Instructions Page Content
  formsInstructions: [
    {
      id: "l1",
      title: "Popular Forms & Instructions",
      list: [
        {
          id: "l11",
          name: "Form 1040",
          link: "https://www.irs.gov/forms-pubs/about-form-1040",
          description: "Individual Tax Return",
        },
        {
          id: "l12",
          name: "Form 1040 Instructions",
          link: "https://www.irs.gov/pub/irs-pdf/i1040gi.pdf",
          description: "Instructions for Form 1040",
        },
        {
          id: "l13",
          name: "Form W-9",
          link: "https://www.irs.gov/forms-pubs/about-form-w-9",
          description:
            "Request for Taxpayer Identification Number (TIN) and Certification",
        },
        {
          id: "l14",
          name: "Form 4506-T",
          link: "https://www.irs.gov/forms-pubs/about-form-4506-t",
          description: "Request for Transcript of Tax Return",
        },
      ],
    },
    {
      id: "l2",
      title: "",
      list: [
        {
          id: "l21",
          name: "Form W-4",
          link: "https://www.irs.gov/forms-pubs/about-form-w-4",
          description: "Employee's Withholding Certificate",
        },
        {
          id: "l22",
          name: "Form 941",
          link: "https://www.irs.gov/forms-pubs/about-form-941",
          description: "Employer's Quarterly Federal Tax Return",
        },
        {
          id: "l23",
          name: "Form W-2",
          link: "https://www.irs.gov/forms-pubs/about-form-w-2",
          description:
            "Employers engaged in a trade or business who pay compensation",
        },
        {
          id: "l24",
          name: "Form 9465",
          link: "https://www.irs.gov/forms-pubs/about-form-9465",
          description: "Installment Agreement Request",
        },
      ],
    },
    {
      id: "l3",
      title: "Popular For Tax Pros",
      list: [
        {
          id: "l31",
          name: "Form 1040-X",
          link: "https://www.irs.gov/forms-pubs/about-form-1040x",
          description: "Amend/Fix Return",
        },
        {
          id: "l32",
          name: "Form 2848",
          link: "https://www.irs.gov/forms-pubs/about-form-2848",
          description: "Apply for Power of Attorney",
        },
        {
          id: "l33",
          name: "Form W-7",
          link: "https://www.irs.gov/forms-pubs/about-form-w-7",
          description: "Apply for an ITIN",
        },
        {
          id: "l34",
          name: "Circular 230",
          link: "https://www.irs.gov/pub/irs-pdf/pcir230.pdf",
          description: "Rules Governing Practice before IRS",
        },
      ],
    },
  ],
};
// exporting all objects and arrays of website content

const News = [
  {
    title: "Post Not Found",
    description: "Post Not Found",
    url: "",
    keywords: [],
    date: "2023-05-25",
    img: `${process.env.PUBLIC_URL}/assets/img/pages/about-header.webp`,
    references: ['southtcpodiatry.com', 'reneaucpa.com', 'texasfostercare.org']
  }
  , {
    title: "Tax Reform Bill Passed by Congress",
    description: "The latest tax reform bill has been passed by Congress, introducing significant changes to corporate tax rates and individual income tax brackets.",
    url: "https://google.com/search?q=tax-reform-bill",
    keywords: ['a', 'b'],
    date: "2023-05-25",
    category: 'Tax Preparation',
    img: `${process.env.PUBLIC_URL}/assets/img/pages/about-header.webp`,
    references: ['southtcpodiatry.com', 'reneaucpa.com', 'texasfostercare.org']
  },
  {
    title: "New Accounting Standards for Cryptocurrency",
    description: "The Financial Accounting Standards Board (FASB) has released new guidelines for accounting and reporting of cryptocurrency transactions. ",
    url: "https://google.com/search?q=accounting-cryptocurrency",
    keywords: ['a', 'b'],
    date: "2023-05-25",
    category: 'Tax Preparation',
    img: `${process.env.PUBLIC_URL}/assets/img/pages/about-header.webp`,
    references: ['southtcpodiatry.com', 'reneaucpa.com', 'texasfostercare.org']
  },
  {
    title: "IRS Extends Tax Filing Deadline for Businesses",
    description: "The IRS has announced an extension to the tax filing deadline for businesses affected by recent natural disasters.",
    url: "https://google.com/search?q=irs-tax-deadline-extension",
    keywords: ['a', 'b'],
    category: 'Tax Preparation',
    img: `${process.env.PUBLIC_URL}/assets/img/pages/about-header.webp`,
    date: "2023-05-25"
  }];
export { ImageContent, Headings, TeamData, PagesData, News };
