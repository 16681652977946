import React from "react";

// Components
import Breadcrumbs from "../Breadcrumbs";
import MetaHelmet from "../SEO/MetaHelmet";

// Content
import ServicesList from "../Sections/ServicesList";
import { Headings } from "../jsComponents/ContentData";
import SeoContent from "../SEO/SeoContent";

const Services = () => {
  Headings.services.pageNav = "Services";
  const [content] = SeoContent.pages.filter(
    (page) => page.page === "/services"
  );
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.services} />
      <ServicesList />
    </>
  );
};

export default Services;
