//Components
import Breadcrumbs from "../../Breadcrumbs";
import MetaHelmet from "../../SEO/MetaHelmet";
//Content
import { Headings } from "../../jsComponents/ContentData";
import SeoContent from "../../SEO/SeoContent";

const reiParas = [
  "Our firm specializes in offering comprehensive real estate investing services designed to help investors maximize returns and achieve their financial goals. With our expertise in market analysis, property evaluation, and portfolio management, we provide tailored solutions that mitigate risks and optimize investment performance. Whether you are a seasoned investor or new to the real estate market, our team of seasoned professionals brings deep industry knowledge and a track record of success to guide you through the complexities of real estate investing.",
  "One of the key advantages of our real estate investing services is our ability to provide personalized attention and customized strategies. We understand that each investor has unique objectives and risk tolerance levels. By working closely with our clients, we develop investment strategies that align with their specific goals, whether it's acquiring income-generating properties, diversifying their portfolio, or exploring alternative investment opportunities. Our team conducts thorough market research, performs rigorous property evaluations, and implements effective property management strategies to ensure optimal performance and maximum returns.",
  "When you choose our real estate investing services, you gain access to a wide range of benefits. Our team of professionals offers expert guidance throughout the entire investment process, from identifying lucrative opportunities to negotiating favorable terms and conducting due diligence. We stay abreast of emerging market trends and potential growth areas, providing you with valuable insights to make informed investment decisions. Additionally, our proactive risk management approach helps minimize potential risks and protect your investment. With our commitment to maximizing returns and delivering exceptional results, we strive to help you build and grow a successful real estate portfolio.",
];

const REIContent = () => {
  return (
    <section id="personal-tax-service">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Real Estate Investing Services</h2>
        </div>
        <div className="row gy-1">
          {reiParas.map((para) => {
            return (
              <p align="justify" style={{ lineHeight: 2 }} key={Math.random()}>
                &nbsp;&nbsp;{para}
              </p>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default function ServicesRealEstateInvesting() {
  Headings.services.pageNav = "Real Estate Investing";
  const [content] = SeoContent.pages.filter(
    (page) => page.page === "/services-rei"
  );
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.services} />;
      <REIContent />
    </>
  );
}
