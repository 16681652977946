//Components
import Breadcrumbs from "../../Breadcrumbs";
import MetaHelmet from "../../SEO/MetaHelmet";
//Content
import { Headings } from "../../jsComponents/ContentData";
import SeoContent from "../../SEO/SeoContent";

const NPTSParas = [
  "Non-profit organizations play a crucial role in driving social change and addressing pressing societal issues. However, navigating the complex landscape of tax regulations can be a challenge for these organizations. Our firm specializes in providing comprehensive non-profit tax services to help organizations meet their compliance requirements while maximizing their impact. With our in-depth knowledge of non-profit tax laws and regulations, we offer tailored solutions that ensure tax efficiency, financial transparency, and regulatory compliance for non-profit entities.",
  "Managing tax obligations and maintaining financial transparency are crucial for non-profit organizations to fulfill their missions effectively. Our firm specializes in providing comprehensive non-profit tax services designed to navigate the complex tax landscape and ensure compliance. With our expertise in non-profit tax regulations, we help organizations optimize their tax strategies, maximize exemptions and deductions, and maintain the necessary records for financial transparency and accountability.",
];
const NPTServicesList = [
  "Preparation and filing of Form 990: We assist non-profit organizations in accurately preparing and filing their annual Form 990, ensuring compliance with IRS regulations and providing transparency to stakeholders.",
  "State and local tax filings: Our team handles the complexities of state and local tax filings, ensuring that non-profit organizations meet their tax obligations in various jurisdictions.",
  "Compliance with IRS guidelines: We provide guidance and support to ensure non-profit organizations adhere to IRS guidelines, including maintaining appropriate records and documentation for tax-exempt status.",
  "Tax planning strategies: Our experts develop tax planning strategies tailored to the unique needs of non-profit organizations, helping to minimize tax liabilities and maximize financial resources for mission-driven initiatives.",
  "Tax-saving opportunities: We identify potential tax-saving opportunities specific to non-profit organizations, such as exemptions and deductions, to optimize tax outcomes and increase available funds for impactful projects.",
  "Financial transparency and accountability: Our services focus on maintaining financial transparency and accountability, assisting non-profits in maintaining accurate records and meeting reporting requirements to instill trust among stakeholders.",
  "Compliance with regulatory changes: We stay informed about the latest tax laws, regulations, and reporting requirements specific to the non-profit sector, ensuring organizations remain compliant and updated with evolving tax regulations.",
];

const NPTSContent = () => {
  return (
    <section id="personal-tax-service">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Non-Profit Tax Services</h2>
        </div>
        <div className="row gy-1">
          {NPTSParas.map((para) => {
            return (
              <p align="justify" style={{ lineHeight: 2 }} key={Math.random()}>
                &ensp;&ensp;{para}
              </p>
            );
          })}
          <p
            align="justify"
            style={{ lineHeight: 2, fontWeight: "bold", fontSize: "1.3rem" }}
          >
            Our Comprehensive Non-Profit Tax Services...
          </p>
          <br />
          <ul className="mt-2 ps-5" style={{ lineHeight: 2 }}>
            {NPTServicesList.map((li) => {
              return <li key={Math.random()}>{li}</li>;
            })}
            and more...
          </ul>
        </div>
      </div>
    </section>
  );
};

export default function ServicesNonProfitTax() {
  Headings.services.pageNav = "Non-Profit Tax";
  const [content] = SeoContent.pages.filter(
    (page) => page.page === "/services-nptax"
  );
  return (
    <>
      <MetaHelmet seo={content} />
      <Breadcrumbs content={Headings.services} />;
      <NPTSContent />
    </>
  );
}
