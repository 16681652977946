import React, { useState } from "react";
import { Tooltip, Zoom } from "@mui/material";

const SocialMediaIcons = (props) => {
  const [hover, setHover] = useState({});

  const navbarMobile = props.navbarMobile;
  const head = props.head;
  const foot = props.foot;
  const smfoot = props.smfoot;

  const toggleHover = (name, isHovered) => {
    setHover({ ...hover, [name]: isHovered });
  };

  const iconStyles = (icon) => ({
    fontSize: foot ? "1.9rem" : smfoot ? "1.5rem" : "1.2rem",
    margin: foot && "0 0.7rem",
    color: hover[icon]
      ? !foot && !smfoot && "white"
      : navbarMobile
      ? "rgba(255, 255, 255, 0.6)"
      : "rgb(49, 84, 124)",
    transition: "0.3s",
    boxShadow: hover[icon] && "0px 0px 25px 2px #97d6f7",
    borderRadius: "45%",
  });

  const styles = {
    div: {
      border: "none",
      boxShadow: head && !navbarMobile && "0px 0px 20px 0.5px #48abe0",
      backgroundColor: head && !navbarMobile && "rgba(255, 255, 255, 0.6)",
      borderRadius: "20px",
      padding: navbarMobile ? "0.6rem" : "0.2rem",
      paddingRight: !navbarMobile ? "0.4rem" : "",
    },
  };

  return (
    <div
      className={`d-flex ${
        foot || smfoot
          ? "justify-content-center align-center"
          : "justify-content-right align-right"
      }  ${head ? !navbarMobile && "ms-5" : ""} ${
        foot && "mt-4 d-none d-lg-block"
      } ${smfoot && "mt-2 d-lg-none"}`}
      style={styles.div}
    >
      <Tooltip TransitionComponent={Zoom} title="Call" arrow>
        <a href="tel:+1 (830) 632-5226" rel="noreferrer" className="px-1">
          <i
            style={iconStyles("phone")}
            onMouseEnter={() => toggleHover("phone", true)}
            onMouseLeave={() => toggleHover("phone", false)}
            className="ri-phone-fill"
          ></i>
        </a>
      </Tooltip>
      <Tooltip TransitionComponent={Zoom} title="Email" arrow>
        <a
          className="px-1"
          href="mailto:info@reneaucpa.com?subject=Mail%20from%20Our%20Site"
          target="_blank"
          rel="noreferrer"
        >
          <i
            style={iconStyles("email")}
            onMouseEnter={() => toggleHover("email", true)}
            onMouseLeave={() => toggleHover("email", false)}
            className="ri-mail-fill"
          ></i>
        </a>
      </Tooltip>
      <Tooltip TransitionComponent={Zoom} title="Locate in Google Maps" arrow>
        <a
          className="px-1"
          href="https://goo.gl/maps/zP63rBHyV63cv1no9"
          target="_blank"
          rel="noreferrer"
        >
          <i
            style={iconStyles("gps")}
            onMouseEnter={() => toggleHover("gps", true)}
            onMouseLeave={() => toggleHover("gps", false)}
            className="ri-map-pin-fill"
          ></i>
        </a>
      </Tooltip>
      <Tooltip TransitionComponent={Zoom} title="Review Us on Google" arrow>
        <a
          className="px-1"
          href="https://g.page/elton-m--reneau-cpa/review?gm"
          target="_blank"
          rel="noreferrer"
        >
          <i
            style={iconStyles("grev")}
            onMouseEnter={() => toggleHover("grev", true)}
            onMouseLeave={() => toggleHover("grev", false)}
            className="ri-thumb-up-fill"
          ></i>
        </a>
      </Tooltip>
      <Tooltip TransitionComponent={Zoom} title="Facebook" arrow>
        <a
          href="https://www.facebook.com/reneaucpa"
          className="px-1"
          target="_blank"
          rel="noreferrer"
        >
          <i
            style={iconStyles("fb")}
            onMouseEnter={() => toggleHover("fb", true)}
            onMouseLeave={() => toggleHover("fb", false)}
            className="ri-facebook-circle-fill"
          ></i>
        </a>
      </Tooltip>
      <Tooltip TransitionComponent={Zoom} title="Instagram" arrow>
        <a
          href="https://www.instagram.com/reneaucpa"
          className="px-1"
          target="_blank"
          rel="noreferrer"
        >
          <i
            style={iconStyles("insta")}
            onMouseEnter={() => toggleHover("insta", true)}
            onMouseLeave={() => toggleHover("insta", false)}
            className="ri-instagram-fill"
          ></i>
        </a>
      </Tooltip>
    </div>
  );
};

export default SocialMediaIcons;
